import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import FicheUsdzObjectSearch from "../fiches/FicheUsdzObjectSearch";
import "./InfiniteScrollUsdzSearchComponent.css";

type Item = {
  id: number | string;
  name: string;
  usdz_name: string; // Added prop
  updated_at: string; // Added prop
};

interface InfiniteScrollUsdzSearchComponentProps {
  numItems: number;
  items: Item[];
  bucketName: string;
  objectUsdzName: string;
  currentFolderPath: string;
  onRefresh: () => void;
}

const InfiniteScrollUsdzSearchComponent: React.FC<InfiniteScrollUsdzSearchComponentProps> = ({
  numItems,
  items,
  bucketName,
  objectUsdzName,
  currentFolderPath,
  onRefresh
}) => {
  const [displayItems, setDisplayItems] = useState<Item[]>([]);
  const [count, setCount] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    console.log("Items changed:", items);
    setDisplayItems([]);
    setCount(0);
    setHasMore(true);
  }, [items]);

  useEffect(() => {
    if (count === 0) {
      fetchMoreData();
    }
  }, [count, items]);

  const fetchMoreData = () => {
    console.log("Fetching more data...");
    console.log("Current count:", count);
    console.log("Current displayItems length:", displayItems.length);
    const remainingItems = numItems - displayItems.length;
    console.log("Remaining items to fetch:", remainingItems);
    const itemsToFetch = Math.min(remainingItems, 10);
    if (itemsToFetch <= 0) {
      console.log("No more items to fetch");
      setHasMore(false);
      return;
    }

    const newItems = items.slice(displayItems.length, displayItems.length + itemsToFetch);
    setCount(count + itemsToFetch);
    setDisplayItems(prevItems => [...prevItems, ...newItems]);
    console.log("New displayItems length:", displayItems.length + newItems.length);
  };

  return (
    <div className="scroll-subfolder">
      <InfiniteScroll
        dataLength={displayItems.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={''}
      >
        <div className="InfiniteScrollUsdzSearchComponent-container">
          {displayItems.map((item, index) => {
            const itemKey = item.id ?? `${item.name}-${index}`;
            return (
              <FicheUsdzObjectSearch
                key={itemKey}
                objectUsdzName={item.name}
                bucketName={bucketName}
                currentFolderPath={currentFolderPath}
                onDelete={onRefresh}
                usdzName={item.usdz_name} // Pass prop
                updatedAt={item.updated_at} // Pass prop
              />
            );
          })}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default InfiniteScrollUsdzSearchComponent;
