//import React from 'react';
import './SubFolderCreationButton.css';

interface SubFolderCreationButtonProps {
    label: string;
    onClick: () => void;
    style?: React.CSSProperties;
    className?: string;
}

const SubFolderCreationButton: React.FC<SubFolderCreationButtonProps> = ({ label, onClick, style, className }) => {
    return (
        <button
            className={`custom-SubFolderCreationButton ${className}`} 
            onClick={onClick} 
            style={style}
        >
            {label}
        </button>
    );
}

export default SubFolderCreationButton;
