import React from 'react';

interface ArQuickLookViewerProps {
  url: string;
}

const ArQuickLookViewer: React.FC<ArQuickLookViewerProps> = ({ url }) => {
  return (
    <a rel="ar" href={url} style={{ display: 'none' }} id="ar-viewer">
      <img src="/path/to/ar-icon.png" alt="View in AR" />
    </a>
  );
};

export default ArQuickLookViewer;
