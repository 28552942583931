import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabasePublic } from '../../lib/supabaseClient';
import { supabaseUserProfile } from '../../lib/supabaseClient';
import CreateAccountView from './CreateAccountView';

const CreateAccountPage: React.FC = () => {
  const [formData, setFormData] = useState({
    profession: '',
    name: '',
    address: '',
    telephone: '',
    website: '',
    picture: '',
  });

  const [userId, setUserId] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getUser = async () => {
      const { data: { user }, error } = await supabasePublic.auth.getUser();

      if (error) {
        console.error('Error fetching user:', error);
      } else {
        console.log('Fetched user:', user);
        setUserId(user?.id || null);
      }
    };

    getUser();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePictureUpload = (url: string) => {
    setFormData({ ...formData, picture: url });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const insertData = {
      profession: formData.profession,
      name:formData.name,
      address: formData.address,
      telephone: formData.telephone,
      website: formData.website,
      picture: formData.picture,
      user_id: userId,
      created_at: new Date().toISOString(),
    };

    console.log('Data to be sent:', insertData);

    const { data, error } = await supabaseUserProfile
      .from('informations')
      .insert([insertData])
      .select();

    if (error) {
      console.error('Error inserting data:', error);
    } else {
      alert('Data submitted successfully!');
      console.log('Inserted data:', data);
      setFormData({
        profession: '',
        name: '',
        address: '',
        telephone: '',
        website: '',
        picture: '',
      });

      if (userId) {
        const createFolder = async () => {
          const { error: uploadError } = await supabasePublic.storage
            .from(formData.profession)
            .upload(userId + '/.placeholder', new Blob([]), {
              cacheControl: '3600',
              upsert: false
            });

          if (uploadError) {
            console.error("Error creating new folder:", uploadError);
          }
        };

        await createFolder();
      }

      setTimeout(() => {
        navigate('/mainfolder');
      }, 1000);
    }
  };

  return (
    <CreateAccountView
      formData={formData}
      handleChange={handleChange}
      handlePictureUpload={handlePictureUpload}
      handleSubmit={handleSubmit}
    />
  );
};

export default CreateAccountPage;
