import React, { useState, useEffect, useCallback } from "react";
import { supabasePublic } from "../../lib/supabaseClient";
import { supabaseUrlDBLien } from "../../lib/supabaseClient";
import { supabaseUserProfile } from "../../lib/supabaseClient";
import { supabaseLienUsdz } from "../../lib/supabaseClient";
import MainFolderView from "./MainFolderView";
import InputPopup from "../../components/ui/popup/InputPopup";
import { useUser } from "../../utils/UserContext";
import BottomMenu from "../../components/ui/bottom-menu/BottomMenu";

const MainFolderPage: React.FC = () => {
  const [buckets, setBuckets] = useState<any[]>([]);
  const [filteredBuckets, setFilteredBuckets] = useState<any[]>([]);
  const [bucketObjects, setBucketObjects] = useState<any[]>([]);
  const [componentType, setComponentType] =
    useState<"FicheFolder">("FicheFolder");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const { userId } = useUser();

  const fetchBuckets = useCallback(async () => {
    console.log("Fetching buckets...");
    const { data: bucketsData, error: bucketsError } =
      await supabasePublic.storage.listBuckets();
    if (bucketsError) {
      console.error("Error fetching storage buckets:", bucketsError);
      return [];
    }
    console.log("Buckets fetched:", bucketsData);
    return bucketsData;
  }, []);

  const fetchUserProfileInformations = useCallback(async () => {
    if (!userId) {
      console.warn("No user ID found for fetching profile informations.");
      return [];
    }
    console.log("Fetching user profile informations for user ID:", userId);
    const { data: profileData, error: profileError } = await supabaseUserProfile
      .from("informations")
      .select("profession")
      .eq("user_id", userId);
    if (profileError) {
      console.error("Error fetching user profile informations:", profileError);
      return [];
    }
    console.log("User profile informations fetched:", profileData);
    return profileData.map((item: any) => item.profession);
  }, [userId]);

  const fetchBucketObjects = useCallback(
    async (bucketName: string) => {
      if (!userId) {
        console.warn("No user ID found for fetching bucket objects.");
        return [];
      }
      console.log(
        `Fetching objects from bucket: ${bucketName} for user ID:`,
        userId
      );
      const { data: objectsData, error: objectsError } =
        await supabasePublic.storage.from(bucketName).list(userId, {
          limit: 100,
          offset: 0,
          sortBy: { column: "name", order: "asc" },
        });
      if (objectsError) {
        console.error(
          `Error fetching objects from ${bucketName} bucket:`,
          objectsError
        );
        return [];
      }
      console.log(`Objects fetched from ${bucketName} bucket:`, objectsData);
      return objectsData.filter((obj: any) => obj.name !== ".placeholder");
    },
    [userId]
  );

  const fetchData = useCallback(async () => {
    try {
      if (!userId) {
        console.warn("No user ID found for fetching data.");
        return;
      }
      console.log("Fetching data for user ID:", userId);

      const bucketsData = await fetchBuckets();
      setBuckets(bucketsData);

      const profileTypes = await fetchUserProfileInformations();

      if (profileTypes.length > 0) {
        const filtered = bucketsData.filter((bucket) =>
          profileTypes.includes(bucket.name)
        );
        setFilteredBuckets(filtered);

        if (filtered.length > 0) {
          const objectsData = await fetchBucketObjects(filtered[0].name);
          setBucketObjects(objectsData);
        }
      }
    } catch (error) {
      console.error("Error during fetch:", error);
    }
  }, [userId, fetchBuckets, fetchUserProfileInformations, fetchBucketObjects]);

  useEffect(() => {
    console.log("User ID in useEffect:", userId);
    if (userId) {
      fetchData();
    }
  }, [userId, fetchData]);

  const handleCreateFolder = async (mainFolderName: string) => {
    if (!userId || filteredBuckets.length === 0) {
      console.warn(
        "No user ID or no filtered buckets available for creating folder."
      );
      return;
    }

    const bucketName = filteredBuckets[0].name;
    const fullFolderName = `${userId}/${mainFolderName}/`;

    console.log(`Creating folder: ${fullFolderName} in bucket: ${bucketName}`);
    const { error: uploadError } = await supabasePublic.storage
      .from(bucketName)
      .upload(fullFolderName + "/.placeholder", new Blob([]), {
        cacheControl: "3600",
        upsert: false,
      });

    if (uploadError) {
      console.error("Error creating new folder:", uploadError);
      return;
    }

    const newFolder = { name: mainFolderName };
    setBucketObjects((prevObjects) => [...prevObjects, newFolder]);
  };

  const renameFolder = async (
    bucketName: string,
    oldName: string,
    newName: string
  ) => {
    try {
      // Helper function to rename files and folders recursively
      const renameItemsRecursively = async (
        oldPath: string,
        newPath: string
      ) => {
        const { data: items, error } = await supabasePublic.storage
          .from(bucketName)
          .list(oldPath, {
            limit: 1000,
            offset: 0,
            sortBy: { column: "name", order: "asc" },
          });

        if (error) {
          console.error(`Error fetching items from ${oldPath}:`, error);
          return;
        }

        // Ensure new folder structure is created
        const { error: createError } = await supabasePublic.storage
          .from(bucketName)
          .upload(`${newPath}/.placeholder`, new Blob([]), {
            cacheControl: "3600",
            upsert: false,
          });

        if (createError) {
          console.error(`Error creating folder ${newPath}:`, createError);
          return;
        }

        // Rename each item
        for (const item of items) {
          const oldItemPath = `${oldPath}/${item.name}`;
          const newItemPath = `${newPath}/${item.name}`;

          if (item.name === ".placeholder") {
            continue; // Skip the .placeholder file
          }

          if (!item.name.includes(".")) {
            // If the item is a folder, recurse into it
            await renameItemsRecursively(oldItemPath, newItemPath);
          } else {
            // If the item is a file, move it
            const { error: moveError } = await supabasePublic.storage
              .from(bucketName)
              .move(oldItemPath, newItemPath);

            if (moveError) {
              console.error(
                `Error moving file from ${oldItemPath} to ${newItemPath}:`,
                moveError
              );
            } else {
              console.log(
                `Successfully moved file from ${oldItemPath} to ${newItemPath}`
              );

              // If the item is a .usdz file, update its database entry
              if (item.name.endsWith(".usdz")) {
                const usdzNameWithoutExtension = item.name.slice(0, -5);
                const usdzName =
                  usdzNameWithoutExtension.charAt(0).toUpperCase() +
                  usdzNameWithoutExtension.slice(1);

                // Fetch the entry to get the usdz_id
                const { data: listData, error: fetchError } =
                  await supabaseLienUsdz
                    .from(`${bucketName}_lien_usdz`)
                    .select("id")
                    .eq("user_id", userId)
                    .eq("usdz_name", usdzName);

                if (fetchError) {
                  console.error(
                    "Error fetching usdz entry from the database:",
                    fetchError
                  );
                } else if (listData && listData.length > 0) {
                  const ObjectUsdzId = listData[0].id;
                  const newUrl = `${supabaseUrlDBLien}${bucketName}/${newItemPath}`;

                  console.log(
                    `Updating usdz entry id ${ObjectUsdzId} with URL ${newUrl}`
                  );

                  const { data: dbData, error: dbError } =
                    await supabaseLienUsdz
                      .from(`${bucketName}_lien_usdz`)
                      .update({
                        lien_url: newUrl,
                        usdz_name: usdzName,
                        updated_at: new Date().toISOString(),
                      })
                      .eq("id", ObjectUsdzId)
                      .select();

                  if (dbError) {
                    console.error(
                      "Error updating usdz entry in the database:",
                      dbError
                    );
                  } else {
                    console.log(
                      `Successfully updated database entry for usdz file: ${newItemPath}`
                    );
                  }
                } else {
                  console.log(`No usdz entry found for usdz_name: ${usdzName}`);
                }
              }
            }
          }
        }
      };

      // Helper function to delete the old folder structure
      const deleteOldFolderRecursively = async (folderPath: string) => {
        const { data: items, error } = await supabasePublic.storage
          .from(bucketName)
          .list(folderPath, {
            limit: 1000,
            offset: 0,
            sortBy: { column: "name", order: "asc" },
          });

        if (error) {
          console.error(`Error fetching items from ${folderPath}:`, error);
          return;
        }

        // Iterate over each item in the list
        for (const item of items) {
          const itemPath = `${folderPath}/${item.name}`;

          if (!item.name.includes(".")) {
            // If the item is a folder, recursively delete its contents
            await deleteOldFolderRecursively(itemPath);
          } else {
            // If the item is a file, delete it
            const { error: removeError } = await supabasePublic.storage
              .from(bucketName)
              .remove([`${itemPath}`]);

            if (removeError) {
              console.error(`Error removing file: ${itemPath}`, removeError);
            } else {
              console.log(`Successfully removed file: ${itemPath}`);
            }
          }
        }

        // After deleting all contents, delete the folder itself
        const { error: removeFolderError } = await supabasePublic.storage
          .from(bucketName)
          .remove([`${folderPath}`]);

        if (removeFolderError) {
          console.error(
            `Error removing folder: ${folderPath}`,
            removeFolderError
          );
        } else {
          console.log(`Successfully removed folder: ${folderPath}`);
        }
      };

      // Start the recursive renaming process from the root folder
      await renameItemsRecursively(
        `${userId}/${oldName}`,
        `${userId}/${newName}`
      );

      // Delete the old folder structure
      await deleteOldFolderRecursively(`${userId}/${oldName}`);

      // Update the bucket objects to reflect the renamed folder
      setBucketObjects((prevObjects) =>
        prevObjects.map((obj) =>
          obj.name === oldName ? { ...obj, name: newName } : obj
        )
      );
    } catch (error) {
      console.error("Error renaming folder:", error);
    }
  };

  const deleteFolder = async (bucketName: string, folderPath: string) => {
    if (!userId) {
      console.warn("No user ID found for deleting folder.");
      return;
    }

    try {
      console.log(
        `Attempting to delete folder: ${folderPath} from bucket: ${bucketName}`
      );

      // List all files and folders in the current folder
      const { data: list, error: listError } = await supabasePublic.storage
        .from(bucketName)
        .list(`${userId}/${folderPath}`, {
          limit: 1000,
          offset: 0,
          sortBy: { column: "name", order: "asc" },
        });

      if (listError) {
        console.error("Error listing files and folders:", listError);
        return;
      }

      console.log(`Items in folder to be deleted:`, list);

      // Iterate over each item in the list
      for (const item of list) {
        const itemPath = `${folderPath}/${item.name}`;

        if (!item.name.includes(".")) {
          // If the item is a folder (no file extension), recursively delete its contents
          await deleteFolder(bucketName, itemPath);
        } else {
          // If the item is a file (including .placeholder), delete it
          const { error: removeError } = await supabasePublic.storage
            .from(bucketName)
            .remove([`${userId}/${itemPath}`]);

          if (removeError) {
            console.error(`Error removing file: ${itemPath}`, removeError);
          } else {
            console.log(`Successfully removed file: ${itemPath}`);

            // Check if the file is a .usdz file and delete its database entry
            if (item.name.endsWith(".usdz")) {
              const usdzNameWithoutExtension = item.name.slice(0, -5);
              const usdzName =
                usdzNameWithoutExtension.charAt(0).toUpperCase() +
                usdzNameWithoutExtension.slice(1);

              // Fetch the entry to get the usdz_id
              const { data: listData, error: fetchError } =
                await supabaseLienUsdz
                  .from(`${bucketName}_lien_usdz`)
                  .select("id")
                  .eq("user_id", userId)
                  .eq("usdz_name", usdzName);

              if (fetchError) {
                console.error(
                  "Error fetching usdz entry from the database:",
                  fetchError
                );
              } else if (listData && listData.length > 0) {
                const usdzId = listData[0].id;
                console.log(
                  `Deleting database entry for usdz file: ${item.name}, ID: ${usdzId}`
                );

                // Delete the database entry
                const { error: dbError } = await supabaseLienUsdz
                  .from(`${bucketName}_lien_usdz`)
                  .delete()
                  .eq("id", usdzId);

                if (dbError) {
                  console.error(
                    "Error deleting usdz entry from the database:",
                    dbError
                  );
                } else {
                  console.log(
                    `Successfully deleted database entry for usdz file: ${item.name}`
                  );
                }
              }
            }
          }
        }
      }

      // After deleting all contents, delete the folder itself
      const { error: removeFolderError } = await supabasePublic.storage
        .from(bucketName)
        .remove([`${userId}/${folderPath}`]);

      if (removeFolderError) {
        console.error(
          `Error removing folder: ${folderPath}`,
          removeFolderError
        );
      } else {
        console.log(`Successfully removed folder: ${folderPath}`);
      }

      // Update the bucket objects to reflect the deleted folder
      setBucketObjects((prevObjects) =>
        prevObjects.filter((obj) => obj.name !== folderPath)
      );
    } catch (error) {
      console.error("Error deleting folder:", error);
    }
  };

  return (
    <>
      <MainFolderView
        filteredBuckets={filteredBuckets}
        bucketObjects={bucketObjects}
        onButtonClick={() => setIsPopupOpen(true)}
        componentType={componentType}
        bucketName={filteredBuckets.length > 0 ? filteredBuckets[0].name : ""}
        renameFolder={renameFolder}
        deleteFolder={deleteFolder}
      />
      {isPopupOpen && (
        <InputPopup
          title="Rajoute le nom du stockage"
          onClose={() => setIsPopupOpen(false)}
          onSubmit={(inputValue: string) => {
            handleCreateFolder(inputValue);
            setIsPopupOpen(false);
          }}
          placeholder={"Catégorie / Artiste / Autres"}
        />
      )}
      <BottomMenu
        bucketName={filteredBuckets.length > 0 ? filteredBuckets[0].name : ""}
      />
    </>
  );
};

export default MainFolderPage;
