import React, { useEffect, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabasePublic } from '../lib/supabaseClient';
import Loading from '../components/ui/loading/Loading';

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const checkSessionAndVerification = async () => {
      try {
        const { data: { session }, error: sessionError } = await supabasePublic.auth.getSession();
        if (sessionError || !session) {
          navigate('/login');
          return;
        }

        const { data: { user }, error: userError } = await supabasePublic.auth.getUser();
        if (userError || !user) {
          navigate('/login');
          return;
        }

        if (!user.email_confirmed_at) {
          navigate('/verification');
          return;
        }

        if (isMounted) {
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error checking session and verification:', error);
        navigate('/login');
      }
    };

    checkSessionAndVerification();

    return () => {
      isMounted = false;
    };
  }, [navigate]);

  if (isLoading) {
    return <Loading />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
