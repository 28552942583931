import React, { useState, useEffect } from "react";
import "./FicheUsdzObject.css";
import closeIcon from "../../../assets/delete.png";
import editIcon from "../../../assets/edit.png";
import viewer3DIcon from "../../../assets/usdz-icon.png";
import transferIcon from "../../../assets/transfer-usdz.png";
import tagIcon from "../../../assets/tag-icon.png";
import InputPopup from "../popup/InputPopup";
import ConfirmPopup from "../popup/ConfirmPopup";
import TagPopup from "../popup/TagPopup";
import TransfertUsdzPopup from "../popup/TransfertUsdzPopup"; // Import the new popup
import { supabasePublic } from "../../../lib/supabaseClient";
import { supabaseLienUsdz } from "../../../lib/supabaseClient";
import ArQuickLookViewer from "../../utils/ArQuickLookViewer";
import { supabaseUrlDBLien } from "../../../lib/supabaseClient";

interface FicheUsdzObjectProps {
  objectUsdzName: string;
  bucketName: string;
  currentFolderPath: string;
  onDelete: () => void;
  onRefresh: () => void; // Add onRefresh prop
}

const FicheUsdzObject: React.FC<FicheUsdzObjectProps> = ({
  objectUsdzName: initialObjectUsdzName,
  bucketName,
  currentFolderPath,
  onDelete,
  onRefresh, // Destructure onRefresh
}) => {
  const [isInputPopupOpen, setIsInputPopupOpen] = useState(false);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [isTagPopupOpen, setIsTagPopupOpen] = useState(false);
  const [isTransferPopupOpen, setIsTransferPopupOpen] = useState(false); // New state for transfer popup
  const [popupTitle, setPopupTitle] = useState("");
  const [objectUsdzLink, setObjectUsdzLink] = useState<string | null>(null);
  const [objectUsdzName, setObjectUsdzName] = useState(initialObjectUsdzName);
  const [tags, setTags] = useState<string[]>([]);

  const fetchTags = async () => {
    const { data: sessionData, error: sessionError } =
      await supabasePublic.auth.getSession();
    if (sessionError) {
      console.error("Error fetching session data:", sessionError);
      return;
    }
    const userId = sessionData?.session?.user.id;
    if (!userId || !bucketName || !objectUsdzName) return;

    const currentFolderPathUserWithId = `${userId}/${currentFolderPath}/`;

    const { data: listData, error: listError } = await supabasePublic.storage
      .from(bucketName)
      .list(currentFolderPathUserWithId, {
        limit: 100,
        offset: 0,
        sortBy: { column: "name", order: "asc" },
        search: objectUsdzName,
      });

    if (listError) {
      console.error("Error fetching folder contents:", listError);
    } else {
      if (listData && listData.length > 0) {
        const ObjectUsdzId = listData[0].id;

        const { data: dbData, error: dbError } = await supabaseLienUsdz
          .from(`${bucketName}_lien_usdz`)
          .select("*")
          .eq("usdz_id", ObjectUsdzId);

        if (dbError) {
          console.error("Error fetching data from the database:", dbError);
        } else {
          if (dbData && dbData.length > 0) {
            const fetchedTags = dbData[0].usdz_tag;

            try {
              const parsedTags = JSON.parse(fetchedTags);
              setTags(Array.isArray(parsedTags) ? parsedTags : []);
            } catch (e) {
              console.error("Error parsing tags:", e);
              setTags([]);
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    fetchTags();
  }, [objectUsdzName, bucketName, currentFolderPath]);

  const handleViewer3DIconClick = async () => {
    if (!isInputPopupOpen && !isConfirmPopupOpen) {
      const { data: sessionData, error: sessionError } =
        await supabasePublic.auth.getSession();
      if (sessionError) {
        console.error("Error fetching session data:", sessionError);
        return;
      }
      const userId = sessionData?.session?.user.id;
      if (!userId || !bucketName || !objectUsdzName) return;

      const currentFolderPathUserWithId = `${userId}/${currentFolderPath}/`;

      const { data: listData, error: listError } = await supabasePublic.storage
        .from(bucketName)
        .list(currentFolderPathUserWithId, {
          limit: 100,
          offset: 0,
          sortBy: { column: "name", order: "asc" },
          search: objectUsdzName,
        });

      if (listError) {
        console.error("Error fetching folder contents:", listError);
      } else {
        if (listData && listData.length > 0) {
          const ObjectUsdzId = listData[0].id;

          const { data: dbData, error: dbError } = await supabaseLienUsdz
            .from(`${bucketName}_lien_usdz`)
            .select("*")
            .eq("usdz_id", ObjectUsdzId);

          if (dbError) {
            console.error("Error fetching data from the database:", dbError);
          } else {
            if (dbData && dbData.length > 0) {
              const fetchedLink = dbData[0].lien_url;
              setObjectUsdzLink(fetchedLink);
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if (objectUsdzLink) {
      const arViewer = document.getElementById("ar-viewer");
      if (arViewer) {
        arViewer.click();
        setObjectUsdzLink(null);
      }
    }
  }, [objectUsdzLink]);

  const handleIconClick = (type: "input" | "confirm" | "tag" | "transfer", title: string) => {
    setPopupTitle(title);
    if (type === "input") {
      setIsInputPopupOpen(true);
    } else if (type === "confirm") {
      setIsConfirmPopupOpen(true);
    } else if (type === "tag") {
      setIsTagPopupOpen(true);
    } else if (type === "transfer") {
      setIsTransferPopupOpen(true);
    }
  };

  const handleCloseInputPopup = () => {
    setIsInputPopupOpen(false);
  };

  const handleCloseConfirmPopup = () => {
    setIsConfirmPopupOpen(false);
  };

  const handleCloseTagPopup = () => {
    setIsTagPopupOpen(false);
  };

  const handleCloseTransferPopup = () => {
    setIsTransferPopupOpen(false);
  };

  const handleTransferPopup = async (newFolderPath: string) => {
    const { data: sessionData, error: sessionError } =
      await supabasePublic.auth.getSession();
    if (sessionError) {
      console.error("Error fetching session data:", sessionError);
      return;
    }
    const userId = sessionData?.session?.user.id;
    if (!userId || !bucketName || !objectUsdzName || !currentFolderPath || !newFolderPath) return;

    const currentFolderPathUserWithId = `${userId}/${currentFolderPath}/`;
    const fromPath = `${currentFolderPathUserWithId}${objectUsdzName}.usdz`;
    const toPath = `${userId}/${newFolderPath}/${objectUsdzName}.usdz`;

    const { data: moveData, error: moveError } = await supabasePublic.storage
      .from(bucketName)
      .move(fromPath, toPath);

    if (moveError) {
      console.error("Error transferring the file:", moveError);
      return;
    } else {
      setIsTransferPopupOpen(false);
      setObjectUsdzName(objectUsdzName);

      const { data: listData, error: listError } = await supabasePublic.storage
        .from(bucketName)
        .list(`${userId}/${newFolderPath}/`, {
          limit: 100,
          offset: 0,
          sortBy: { column: "name", order: "asc" },
          search: `${objectUsdzName}.usdz`,
        });

      if (listError) {
        console.error("Error fetching updated folder contents:", listError);
        return;
      } else {
        if (listData && listData.length > 0) {
          const ObjectUsdzId = listData[0].id;

          const newUrl = `${bucketName}/${toPath}`;

          const { data: dbData, error: dbError } = await supabaseLienUsdz
            .from(`${bucketName}_lien_usdz`)
            .update({
              lien_url: `${supabaseUrlDBLien}${newUrl}`,
              updated_at: new Date().toISOString(),
            })
            .eq("usdz_id", ObjectUsdzId)
            .select();

          if (dbError) {
            console.error("Error updating URL in the database:", dbError);
          } else {
            console.log("URL updated in the database successfully:", dbData);
            onRefresh(); // Trigger a refresh after the transfer
          }
        } else {
          console.error("No matching file found after transfer");
        }
      }
    }
  };

  const handleSubmitPopup = async (inputValue: string) => {
    const { data: sessionData, error: sessionError } =
      await supabasePublic.auth.getSession();
    if (sessionError) {
      console.error("Error fetching session data:", sessionError);
      return;
    }
    const userId = sessionData?.session?.user.id;
    if (!userId || !bucketName || !objectUsdzName || !currentFolderPath) return;

    const currentFolderPathUserWithId = `${userId}/${currentFolderPath}/`;
    const fromPath = `${currentFolderPathUserWithId}${objectUsdzName}.usdz`;
    const toPath = `${currentFolderPathUserWithId}${inputValue}.usdz`;

    const { data: moveData, error: moveError } = await supabasePublic.storage
      .from(bucketName)
      .move(fromPath, toPath);

    if (moveError) {
      console.error("Error renaming the file:", moveError);
      return;
    } else {
      setIsInputPopupOpen(false);
      setObjectUsdzName(inputValue);

      const { data: listData, error: listError } = await supabasePublic.storage
        .from(bucketName)
        .list(currentFolderPathUserWithId, {
          limit: 100,
          offset: 0,
          sortBy: { column: "name", order: "asc" },
          search: `${inputValue}.usdz`,
        });

      if (listError) {
        console.error("Error fetching updated folder contents:", listError);
        return;
      } else {
        if (listData && listData.length > 0) {
          const ObjectUsdzId = listData[0].id;

          const newUrl = `${bucketName}/${toPath}`;

          const { data: dbData, error: dbError } = await supabaseLienUsdz
            .from(`${bucketName}_lien_usdz`)
            .update({
              lien_url: `${supabaseUrlDBLien}${newUrl}`,
              usdz_name: inputValue,
              updated_at: new Date().toISOString(),
            })
            .eq("usdz_id", ObjectUsdzId)
            .select();

          if (dbError) {
            console.error("Error updating URL in the database:", dbError);
          } else {
            console.log("URL updated in the database successfully:", dbData);
          }
        } else {
          console.error("No matching file found after rename");
        }
      }
    }
  };

  const handleConfirmPopup = async (confirmed: boolean) => {
    if (confirmed) {
      const { data: sessionData, error: sessionError } =
        await supabasePublic.auth.getSession();
      if (sessionError) {
        console.error("Error fetching session data:", sessionError);
        return;
      }
      const userId = sessionData?.session?.user.id;
      if (!userId || !bucketName || !objectUsdzName || !currentFolderPath)
        return;

      const currentFolderPathUserWithId = `${userId}/${currentFolderPath}/`;
      const filePath = `${currentFolderPathUserWithId}${objectUsdzName}.usdz`;

      const { data: listData, error: listError } = await supabasePublic.storage
        .from(bucketName)
        .list(currentFolderPathUserWithId, {
          limit: 100,
          offset: 0,
          sortBy: { column: "name", order: "asc" },
          search: `${objectUsdzName}.usdz`,
        });

      if (listError) {
        console.error("Error fetching folder contents:", listError);
      } else {
        if (listData && listData.length > 0) {
          const ObjectUsdzId = listData[0].id;

          const { error: dbError } = await supabaseLienUsdz
            .from(`${bucketName}_lien_usdz`)
            .delete()
            .eq("usdz_id", ObjectUsdzId);

          if (dbError) {
            console.error("Error deleting database entry:", dbError);
          } else {
            const { error: deleteError } = await supabasePublic.storage
              .from(bucketName)
              .remove([filePath]);

            if (deleteError) {
              console.error("Error deleting the file:", deleteError);
            } else {
              onDelete();
            }
          }
        } else {
          console.error("No matching file found to delete");
        }
      }
    }
    setIsConfirmPopupOpen(false);
  };

  const handleTagPopupSubmit = async (selectedTags: string[]) => {
    const { data: sessionData, error: sessionError } =
      await supabasePublic.auth.getSession();
    if (sessionError) {
      console.error("Error fetching session data:", sessionError);
      return;
    }
    const userId = sessionData?.session?.user.id;
    if (!userId || !bucketName || !objectUsdzName || !currentFolderPath)
      return;

    const currentFolderPathUserWithId = `${userId}/${currentFolderPath}/`;
    const { data: listData, error: listError } = await supabasePublic.storage
      .from(bucketName)
      .list(currentFolderPathUserWithId, {
        limit: 100,
        offset: 0,
        sortBy: { column: "name", order: "asc" },
        search: `${objectUsdzName}.usdz`,
      });

    if (listError) {
      console.error("Error fetching folder contents:", listError);
    } else {
      if (listData && listData.length > 0) {
        const ObjectUsdzId = listData[0].id;

        const { data: dbData, error: dbError } = await supabaseLienUsdz
          .from(`${bucketName}_lien_usdz`)
          .update({
            usdz_tag: JSON.stringify(selectedTags),
            updated_at: new Date().toISOString(),
          })
          .eq("usdz_id", ObjectUsdzId)
          .select();

        if (dbError) {
          console.error("Error updating tags in the database:", dbError);
        } else {
          setTags(selectedTags);
        }
      }
    }
    setIsTagPopupOpen(false);
  };

  return (
    <div className="fiche-usdz">
      <img
        src={closeIcon}
        className="fiche-icon close-icon"
        alt="Close"
        onClick={(e) => {
          e.stopPropagation();
          handleIconClick("confirm", "Delete Confirmation");
        }}
      />
      <img
        src={editIcon}
        className="fiche-icon edit-icon"
        alt="Edit"
        onClick={(e) => {
          e.stopPropagation();
          handleIconClick("input", "Renommer le fichier");
        }}
      />
      <img
        src={transferIcon}
        className="fiche-icon transfer-icon"
        alt="Transfer"
        onClick={(e) => {
          e.stopPropagation();
          handleIconClick("transfer", "Transfer File");
        }}
      />
      <img
        src={viewer3DIcon}
        className="fiche-icon viewer3D-icon"
        alt="Viewer"
        onClick={(e) => {
          e.stopPropagation();
          handleViewer3DIconClick();
        }}
      />
      <img
        src={tagIcon}
        className="fiche-icon tag-icon"
        alt="Tag"
        onClick={(e) => {
          e.stopPropagation();
          handleIconClick("tag", "Add/Update Tags");
        }}
      />
      <p className="fiche-content">
        {objectUsdzName
          .replace(/\.usdz$/, "")
          .charAt(0)
          .toUpperCase() + objectUsdzName.replace(/\.usdz$/, "").slice(1)}
      </p>

      {objectUsdzLink && <ArQuickLookViewer url={objectUsdzLink} />}
      {isInputPopupOpen && (
        <InputPopup
          title={popupTitle}
          onClose={handleCloseInputPopup}
          onSubmit={handleSubmitPopup}
          placeholder={"Nom d'un sous dossier "}
        />
      )}
      {isConfirmPopupOpen && (
        <ConfirmPopup
          title={popupTitle}
          onClose={handleCloseConfirmPopup}
          onConfirm={handleConfirmPopup}
        />
      )}
      {isTagPopupOpen && (
        <TagPopup
          title={popupTitle}
          onClose={handleCloseTagPopup}
          onAction={handleTagPopupSubmit}
          initialSelectedTags={tags} // Pass the initial selected tags
        />
      )}
      {isTransferPopupOpen && (
        <TransfertUsdzPopup
          title={popupTitle}
          bucketName={bucketName}
          currentFolderPath={currentFolderPath}
          objectUsdzName={objectUsdzName}
          onClose={handleCloseTransferPopup}
          onTransfer={handleTransferPopup}
        />
      )}
    </div>
  );
};

export default FicheUsdzObject;
