import React from 'react';
import './HomeView.css';
import Button from '../../components/ui/buttons/Button';
import animatedLogo from '../../assets/animated-logo.gif';

interface HomeViewProps {
  onNavigate: (path: string) => void;
}

const HomeView: React.FC<HomeViewProps> = ({ onNavigate }) => {
  return (
    <div className="HomePage">
      <div className="HomePage-header">
      <img 
            src= {animatedLogo}
            alt="Header Image" 
            className="Header-image"
          />
      </div>
      <div className="Header-content">

          <div style={{ marginTop: '50px' }}>
            <Button 
              label="Sign Up" 
              onClick={() => onNavigate('/signup')} 
              style={{ marginRight: '10px' }} 
            />
            <Button 
              label="Login" 
              onClick={() => onNavigate('/login')} 
              style={{ marginRight: '10px' }} 
            />
          </div>
        </div>
    </div>
  );
};

export default HomeView;
