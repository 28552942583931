import React from 'react';
import './SignupView.css';

interface SignUpViewProps {
  email: string;
  password: string;
  error: string | null;
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
  handleSignUp: (e: React.FormEvent<HTMLFormElement>) => void;
}

const SignUpView: React.FC<SignUpViewProps> = ({ email, password, error, setEmail, setPassword, handleSignUp }) => {
  return (
    <div className="signup-container">
      <form onSubmit={handleSignUp} className="signup-form">
        <h1 className="signup-title">Sign Up</h1>
        {error && <p className="signup-error">{error}</p>}
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="signup-input"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="signup-input"
        />
        <button type="submit" className="signup-button">Sign Up</button>
      </form>
    </div>
  );
};

export default SignUpView;
