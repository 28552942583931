import React from 'react';
import './VerificationPage.css';

const VerificationPage: React.FC = () => {
  return (
    <div className="verification-container">
      <h1>E-mail de vérification du compte, envoyé</h1>
      <p>Regarde dans ta messagerie, notamment dans les courriers indésirables, pour valider ton inscription</p>
    </div>
  );
};

export default VerificationPage;
