import React from 'react';
import Header from '../../components/ui/bottom-menu/BottomMenu';
import UploadProfilLogo from '../../components/utils/UploadProfilLogo';
import './CreateAccountView.css';
import { useUser } from '../../utils/UserContext';
import Loading from '../../components/ui/loading/Loading';

interface CreateAccountViewProps {
  formData: {
    profession: string;
    name: string;
    address: string;
    telephone: string;
    website: string;
    picture: string;
  };
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  handlePictureUpload: (url: string) => void;
  handleSubmit: (e: React.FormEvent) => void;
}

const CreateAccountView: React.FC<CreateAccountViewProps> = ({
  formData,
  handleChange,
  handlePictureUpload,
  handleSubmit,
}) => {
  const { userId } = useUser();

  return (
    <>
      <Header />
      <div className="AccountPage">
        <h1>Informations sur le compte</h1>
        <form onSubmit={handleSubmit}>
          <div className="account-field">
            <label>Profession:</label>
            <select name="profession" value={formData.profession} onChange={handleChange}>
              <option value="gallery">Galleriste</option>
              <option value="decorator">Décorateur</option>
              <option value="architect">Architecte</option>
              <option value="designer">Designer</option>
              <option value="antiquaire">Antiquaire</option>
            </select>
          </div>
          <div className="account-field">
            <label>Nom de l'entreprise ou de l'artiste :</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className="account-field">
            <label>Addresse :</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
          </div>
          <div className="account-field">
            <label>Telephone :</label>
            <input
              type="text"
              name="telephone"
              value={formData.telephone}
              onChange={handleChange}
            />
          </div>
          <div className="account-field">
            <label>Website :</label>
            <input
              type="text"
              name="website"
              value={formData.website}
              onChange={handleChange}
            />
          </div>
          <div className="account-field">
            <label>Logo utilisé:</label>
            {userId ? (
              <UploadProfilLogo onUpload={handlePictureUpload} filePath={userId} />
            ) : (
              <Loading />
            )}
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </>
  );
};

export default CreateAccountView;
