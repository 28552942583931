import React, { useRef } from "react";
import './UsdzObjectImportationButton.css';

interface UsdzObjectImportationButtonProps {
    label: string;
    onClick: (file: File | null) => void;
    style?: React.CSSProperties;
    className?: string;
}

const UsdzObjectImportationButton: React.FC<UsdzObjectImportationButtonProps> = ({ label, onClick, style, className }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] || null;
        onClick(file);
    };

    return (
        <>
            <button
                className={`custom-UsdzObjectImportationButton ${className}`}
                onClick={handleButtonClick}
                style={style}
            >
                {label}
            </button>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
        </>
    );
};

export default UsdzObjectImportationButton;
