import React, { useState } from 'react';
import './InputPopup.css';

interface InputPopupProps {
    title: string;
    onClose: () => void;
    onSubmit: (inputValue: string) => void;
    placeholder: string;
}

const InputPopup: React.FC<InputPopupProps> = ({ title, onClose, onSubmit, placeholder }) => {
    const [inputValue, setInputValue] = useState('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setInputValue(value.charAt(0).toUpperCase() + value.slice(1));
    };

    const handleSubmit = () => {
        if (inputValue) {
            onSubmit(inputValue);
            onClose();
        }
    };

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <div className="popup-header">
                    <p>{title}</p>
                    <button className="close-button" onClick={onClose}>×</button>
                </div>
                <div className="popup-body">
                    <input
                        type="text"
                        value={inputValue}
                        onChange={handleChange}
                        placeholder={placeholder}
                        className="popup-input"
                    />
                </div>
                <div className="popup-footer">
                    <button onClick={handleSubmit} disabled={!inputValue}>Submit</button>
                </div>
            </div>
        </div>
    );
}

export default InputPopup;
