import React from 'react';
import './ConfirmPopup.css';

interface ConfirmPopupProps {
  title: string;
  onClose: () => void;
  onConfirm: (confirmed: boolean) => void;
}

const ConfirmPopup: React.FC<ConfirmPopupProps> = ({ title, onClose, onConfirm }) => {
  const handleConfirm = (confirmed: boolean) => {
    onConfirm(confirmed);
    onClose();
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="popup-header">
          <h2>{title}</h2>
          <button className="close-button" onClick={onClose}>×</button>
        </div>
        <div className="popup-body">
          <p>Are you sure?</p>
        </div>
        <div className="popup-footer">
          <button onClick={() => handleConfirm(true)}>Yes</button>
          <button onClick={() => handleConfirm(false)}>No</button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmPopup;
