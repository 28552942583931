import React, { useEffect, useState } from 'react';
import { supabasePublic } from '../lib/supabaseClient';
import { useNavigate } from 'react-router-dom';
import TagPopup from '../components/ui/popup/TagPopup';

const TestPage: React.FC = () => {
  const [isChecking, setIsChecking] = useState<boolean>(true);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const navigate = useNavigate();

/*  useEffect(() => {
    const checkVerificationStatus = async () => {
      const { data, error } = await supabasePublic.auth.getUser();

      if (error) {
        console.error('Error fetching user:', error);
        navigate('/verification');
        return;
      }

      const user = data?.user;

      if (user && user.aud === 'authenticated') {
        const isUserVerified = user.user_metadata.email_verified;
        console.log('User is verified:', user.email);
        console.log('User email verified:', user.user_metadata.email_verified)

        if (isUserVerified) {
          console.log('User is verified:', user.email);
          navigate('/uploadPage');
        } else {
          setIsChecking(false);
        }
      } else {
        navigate('/verification');
      }
    };

    checkVerificationStatus();

    const intervalId = setInterval(checkVerificationStatus, 5000);

    return () => clearInterval(intervalId);
  }, [navigate]);
*/
const handleAction = (tags: string[]) => {
  console.log('Selected tags:', tags);
  setSelectedTags(tags);
};
/*
  if (isChecking) {
    return <p>Checking verification status...</p>;
  }
*/
return (
  <div>
    <h1>Waiting for verification...</h1>
    <p>Please check your email and click on the verification link.</p>
    <button onClick={() => setIsPopupOpen(true)}>Show Popup</button>
    {isPopupOpen && (
      <TagPopup
        title="Choisissez au moins 1 tag pour l'objet importé"
        onClose={() => setIsPopupOpen(false)}
        onAction={handleAction}
        initialSelectedTags={selectedTags}
      />
    )}
  </div>
);
};

export default TestPage;
