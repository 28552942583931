import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import SignupPage from "./pages/Signup/SignupPage";
import LoginPage from "./pages/Login/LoginPage";
import HomePage from "./pages/Home/HomePage";
import VerificationPage from "./pages/Emailverificationsend/VerificationPage";
import ProfilePage from "./pages/Profile/ProfilePage";
import TestPage from "./pages/TestPage";
import ConfirmSignupPage from "./pages/Confirmsignup/ConfirmSignUpPage";
import MainFolderPage from "./pages/Mainfolder/MainFolderPage";
import SubFolderPage from "./pages/Subfolder/SubFolderPage";
import CreateAccountPage from "./pages/Createaccount/CreateAccountPage";
import SearchObjectPage from "./pages/Searchobject/SearchObjectPage";

import ProtectedRoute from "./routing/ProtectedRoute";
import PublicRoute from "./routing/PublicRoute";

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/test" element={<TestPage />} />
      <Route
        path="/signup"
        element={
          <PublicRoute>
            <SignupPage />
          </PublicRoute>
        }
      />
      <Route
        path="/login"
        element={
          <PublicRoute>
            <LoginPage />
          </PublicRoute>
        }
      />
      <Route
        path="/:bucketName/profile"
        element={
          <ProtectedRoute>
            <ProfilePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/:bucketName/search"
        element={
          <ProtectedRoute>
            <SearchObjectPage />
          </ProtectedRoute>
        }
      />
      <Route path="/create-account" element={<CreateAccountPage />} />
      <Route path="/confirm-signup" element={<ConfirmSignupPage />} />
      <Route
        path="/:bucketName"
        element={
          <ProtectedRoute>
            <MainFolderPage />
          </ProtectedRoute>
        }
      />
      <Route path="/verification" element={<VerificationPage />} />
      <Route path="/" element={<HomePage />} />
      <Route
        path="/:bucketName/:mainFolderName/*"
        element={
          <ProtectedRoute>
            <SubFolderPage />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default App;
