import React, { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabasePublic } from '../lib/supabaseClient';
import Loading from '../components/ui/loading/Loading';

interface PublicRouteProps {
  children: ReactNode;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const checkSession = async () => {
      try {
        const { data: { session }, error } = await supabasePublic.auth.getSession();
        if (error || !session) {
          if (isMounted) {
            setIsLoading(false);
          }
          return;
        }

        navigate('/mainfolder');
      } catch (error) {
        console.error('Error checking session:', error);
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    checkSession();

    return () => {
      isMounted = false;
    };
  }, [navigate]);

  if (isLoading) {
    return <Loading />;
  }

  return <>{children}</>;
};

export default PublicRoute;
