import React from 'react';
import { useNavigate } from 'react-router-dom';
import HomeView from './HomeView';

const HomePage: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <HomeView 
      onNavigate={handleNavigate}
    />
  );
}

export default HomePage;
