import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import menuIcon from '../../../assets/logo-insitu.png';
import ProfileIcon from '../../../assets/profile-logo.png';
import loupeIcon from '../../../assets/loupe.png';
import './BottomMenu.css';

interface BottomMenuProps {
  bucketName?: string;
}

const BottomMenu: React.FC<BottomMenuProps> = ({ bucketName }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleMenuClick = () => {
    navigate('/mainfolder');
  };

  const handleProfileClick = () => {
    navigate(`/${bucketName}/profile`);
  };

  const handleSearchClick = () => {
    navigate(`/${bucketName}/search`);
  };

  return (
    <header className="header">
      <div className={`header-left ${location.pathname.includes('mainfolder') ? 'active' : ''}`}>
        <img src={menuIcon} alt="logo-insitu" className="header-icon" onClick={handleMenuClick} />
        <span className="header-text">Main</span>
      </div>
      <div className={`header-middle ${location.pathname.includes('search') ? 'active' : ''}`}>
        <img src={loupeIcon} alt="loupe-search" className="header-icon" onClick={handleSearchClick} />
        <span className="header-text">Recherche</span>
      </div>
      <div className={`header-right ${location.pathname.includes('profile') ? 'active' : ''}`}>
        <img src={ProfileIcon} alt="Profile Logo" className="header-icon" onClick={handleProfileClick} />
        <span className="header-text">Profile</span>
      </div>
    </header>
  );
}

export default BottomMenu;
