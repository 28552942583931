import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import './InfiniteScrollMainFolderComponent.css';

type Item = {
  id: number;
  mainFolderName: string;
};

interface InfiniteScrollFolderComponentProps {
  numItems: number;
  Component: React.FC<{ mainFolderName: string; bucketName: string; renameFolder: any; deleteFolder: any }>;
  bucketObjects: any[];
  bucketName: string;
  renameFolder: (bucketName: string, oldName: string, newName: string) => void;
  deleteFolder: (bucketName: string, mainFolderName: string) => void;
}

const InfiniteScrollFolderComponent: React.FC<InfiniteScrollFolderComponentProps> = ({
  numItems,
  Component,
  bucketObjects,
  bucketName,
  renameFolder,
  deleteFolder
}) => {
  const [items, setItems] = useState<Item[]>([]);
  const [count, setCount] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    resetData();
  }, [bucketObjects]);

  const resetData = () => {
    setItems([]);
    setCount(0);
    setHasMore(true);
    fetchMoreData(true);
  };

  const fetchMoreData = (isReset = false) => {
    const startIndex = isReset ? 0 : count;
    const remainingItems = bucketObjects.slice(startIndex).filter(obj => obj.name !== '.placeholder');
    const itemsToFetch = Math.min(remainingItems.length, 10);

    if (itemsToFetch <= 0) {
      setHasMore(false);
      return;
    }

    const newItems = remainingItems.slice(0, itemsToFetch).map((obj, index) => ({
      id: startIndex + index + 1,
      mainFolderName: obj.name
    }));

    setCount(startIndex + itemsToFetch);
    setItems(prevItems => [...prevItems, ...newItems]);
    setHasMore(startIndex + itemsToFetch < numItems);
  };

  return (
    <div className="scroll-mainfolder">
      <InfiniteScroll
        dataLength={items.length}
        next={() => fetchMoreData()}
        hasMore={hasMore}
        loader={''}
      >
        <div className="infinitescroll-container">
          {items.map(item => (
            <Component
              key={item.id}
              mainFolderName={item.mainFolderName}
              bucketName={bucketName}
              renameFolder={renameFolder}
              deleteFolder={deleteFolder}
            />
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default InfiniteScrollFolderComponent;
