import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { supabasePublic } from '../../lib/supabaseClient';
import { supabaseUserProfile } from '../../lib/supabaseClient';
import ProfileView from './ProfileView';

type RouteParams = {
  bucketName: string;
};

const ProfilePage: React.FC = () => {
  const [profiles, setProfiles] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState<string | null>(null);
  const { bucketName } = useParams<RouteParams>();
  const navigate = useNavigate();

  useEffect(() => {
    const getUser = async () => {
      const { data: { user }, error } = await supabasePublic.auth.getUser();

      if (error) {
        console.error('Error fetching user:', error);
      } else {
        console.log('Fetched user:', user);
        setUserId(user?.id || null);
      }
    };

    getUser();
  }, []);

  useEffect(() => {
    if (userId) {
      const fetchProfiles = async () => {
        console.log('Fetching profiles for userId:', userId);
        const { data, error } = await supabaseUserProfile
          .from('informations')
          .select('*')
          .eq('user_id', userId);

        if (error) {
          console.error('Error fetching data:', error);
        } else {
          console.log('Fetched profiles:', data);
          setProfiles(data);
          setLoading(false);
        }
      };

      fetchProfiles();
    } else {
      console.log('User ID is null, skipping fetchProfiles.');
    }
  }, [userId]);

  const handleLogout = async () => {
    const { error } = await supabasePublic.auth.signOut();
    if (error) {
      console.error('Error signing out:', error);
    } else {
      navigate('/');
    }
  };

  if (!bucketName) {
    return <div>Error: bucketName is undefined</div>;
  }

  return (
    <ProfileView bucketName={bucketName} profiles={profiles} loading={loading} onLogout={handleLogout} />
  );
};

export default ProfilePage;
