import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { supabasePublic } from '../lib/supabaseClient';
import Loading from '../components/ui/loading/Loading';

interface UserContextType {
  user: any;
  userId: string | null;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<any>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      setLoading(false);
      return;
    }

    const getUser = async () => {
      const { data: { session } } = await supabasePublic.auth.getSession();
      if (!session) {
        console.error('No active session found.');
        setLoading(false);
        return;
      }

      const { data: { user }, error } = await supabasePublic.auth.getUser();
      if (error) {
        console.error('Error fetching user:', error.message);
      } else {
        setUser(user);
        setUserId(user?.id || null);
        console.log('User fetched:', user);
      }
      setLoading(false);
    };

    const { data } = supabasePublic.auth.onAuthStateChange((event, session) => {
      if (session?.user) {
        setUser(session.user);
        setUserId(session.user.id);
      } else {
        setUser(null);
        setUserId(null);
      }
    });

    getUser();

    return () => {
      data.subscription.unsubscribe();
    };
  }, [location.pathname]);

  if (loading) {
    return <Loading />;
  }

  return (
    <UserContext.Provider value={{ user, userId }}>
      {children}
    </UserContext.Provider>
  );
};
