import React, { useState } from "react";
import { supabasePublic } from "../../lib/supabaseClient";

const MAX_FILE_SIZE = 10 * 1024 * 1024; // Taille maximal du fichier est de 10 MB

const UploadLogo: React.FC<{ onUpload: (url: string) => void, filePath: string }> = ({ onUpload, filePath }) => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(null);
    const files = event.target.files;
    if (!files || files.length === 0) return;

    const selectedFile = files[0];
    if (selectedFile.size > MAX_FILE_SIZE) {
      setError("File size exceeds the maximum limit of 10 MB");
      return;
    }

    setUploading(true);
    const sanitizedFileName = selectedFile.name.replace(/\s+/g, "_");
    const fullFilePath = `${filePath}/${sanitizedFileName}`;

    try {
      const { data, error } = await supabasePublic.storage
        .from("user_profile_logo")
        .upload(fullFilePath, selectedFile, {
          cacheControl: "3600",
          upsert: true,
        });

      if (error) {
        setError(`Error uploading file: ${error.message}`);
        console.error("Error uploading file:", error.message);
      } else {
        console.log("File uploaded:", data);
        const url = supabasePublic.storage
          .from("user_profile_logo")
          .getPublicUrl(fullFilePath).data.publicUrl;
        onUpload(url);
      }
    } catch (uploadError) {
      if (uploadError instanceof Error) {
        setError(`Upload failed: ${uploadError.message}`);
        console.error("Upload failed:", uploadError);
      } else {
        setError("An unknown error occurred during upload.");
        console.error("An unknown error occurred:", uploadError);
      }
    } finally {
      setUploading(false);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} disabled={uploading} />
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default UploadLogo;
