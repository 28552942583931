import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './FicheSubFolder.css';
import closeIcon from '../../../assets/delete.png';
import editIcon from '../../../assets/edit.png';
import InputPopup from '../popup/InputPopup';
import ConfirmPopup from '../popup/ConfirmPopup';
import { supabasePublic, supabaseLienUsdz, supabaseUrlDBLien } from '../../../lib/supabaseClient';

interface FicheSubFolderProps {
  mainFolderName: string;
  bucketName: string;
  subFolderName: string;
  currentFolderPath: string;
  onClick: () => void;
  onDelete: () => void;
}

const FicheSubFolder: React.FC<FicheSubFolderProps> = ({
  mainFolderName,
  bucketName,
  subFolderName,
  currentFolderPath,
  onClick,
  onDelete
}) => {
  const [isInputPopupOpen, setIsInputPopupOpen] = useState(false);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [popupTitle, setPopupTitle] = useState('');
  const [userId, setUserId] = useState<string | null>(null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchUserData = async () => {
      const { data: { user }, error } = await supabasePublic.auth.getUser();
      if (user && user.id && user.id.length > 0) {
        setUserId(user.id);
      } else if (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const handleFicheClick = () => {
    if (!isInputPopupOpen && !isConfirmPopupOpen && userId && mainFolderName && subFolderName) {
      onClick();
    } else {
      console.warn('Cannot navigate: identityId or folderName is missing.');
    }
  };

  const handleIconClick = (type: 'input' | 'confirm', title: string) => {
    setPopupTitle(title);
    if (type === 'input') {
      setIsInputPopupOpen(true);
    } else {
      setIsConfirmPopupOpen(true);
    }
  };

  const handleCloseInputPopup = () => {
    setIsInputPopupOpen(false);
  };

  const handleCloseConfirmPopup = () => {
    setIsConfirmPopupOpen(false);
  };

  const handleSubmitPopup = async (newSubFolderName: string) => {
    if (!userId || !bucketName || !currentFolderPath || !subFolderName || !newSubFolderName) return;

    const oldFolderPath = `${userId}/${currentFolderPath}/${subFolderName}/`;
    const newFolderPath = `${userId}/${currentFolderPath}/${newSubFolderName}/`;

    await renameFolder(bucketName, oldFolderPath, newFolderPath);

    setIsInputPopupOpen(false);
    onDelete(); // Update the UI
  };

  const handleConfirmPopup = async (confirmed: boolean) => {
    if (confirmed) {
      await deleteFolder(bucketName, `${userId}/${currentFolderPath}/${subFolderName}`);
      onDelete();
    }
    setIsConfirmPopupOpen(false);
  };

  const renameFolder = async (bucketName: string, oldFolderPath: string, newFolderPath: string) => {
    try {
      console.log(`Renaming folder: ${oldFolderPath} to ${newFolderPath}`);

      const { data: list, error: listError } = await supabasePublic.storage
        .from(bucketName)
        .list(oldFolderPath, {
          limit: 1000,
          offset: 0,
          sortBy: { column: "name", order: "asc" },
        });

      if (listError) {
        console.error("Error listing files and folders:", listError);
        return;
      }

      for (const item of list) {
        const oldItemPath = `${oldFolderPath}${item.name}`;
        const newItemPath = `${newFolderPath}${item.name}`;

        if (!item.name.includes('.')) {
          // If it's a folder, rename its contents recursively
          await renameFolder(bucketName, oldItemPath, newItemPath);
        } else {
          // If it's a file, move it to the new location
          const { error: moveError } = await supabasePublic.storage
            .from(bucketName)
            .move(oldItemPath, newItemPath);

          if (moveError) {
            console.error(`Error moving file: ${oldItemPath} to ${newItemPath}`, moveError);
          } else {
            console.log(`Successfully moved file: ${oldItemPath} to ${newItemPath}`);

            if (item.name.endsWith('.usdz')) {
              const usdzNameWithoutExtension = item.name.slice(0, -5);
              const usdzName = usdzNameWithoutExtension.charAt(0).toUpperCase() + usdzNameWithoutExtension.slice(1);
              const newUrl = `${newFolderPath}${item.name}`;

              const { data: listData, error: fetchError } = await supabaseLienUsdz
                .from(`${bucketName}_lien_usdz`)
                .select('id')
                .eq('user_id', userId)
                .eq('usdz_name', usdzName);

              if (fetchError) {
                console.error('Error fetching usdz entry from the database:', fetchError);
              } else if (listData && listData.length > 0) {
                const usdzId = listData[0].id;
                console.log(`Updating database entry for usdz file: ${item.name}, ID: ${usdzId}`);

                const { error: dbError } = await supabaseLienUsdz
                  .from(`${bucketName}_lien_usdz`)
                  .update({
                    lien_url: `${supabaseUrlDBLien}${bucketName}/${newUrl}`,
                    usdz_name: usdzName,
                    updated_at: new Date().toISOString(),
                  })
                  .eq('id', usdzId);

                if (dbError) {
                  console.error('Error updating usdz entry in the database:', dbError);
                } else {
                  console.log(`Successfully updated database entry for usdz file: ${item.name}`);
                }
              }
            }
          }
        }
      }

      // Remove the old folder (now empty)
      const { error: removeFolderError } = await supabasePublic.storage
        .from(bucketName)
        .remove([oldFolderPath]);

      if (removeFolderError) {
        console.error(`Error removing old folder: ${oldFolderPath}`, removeFolderError);
      } else {
        console.log(`Successfully removed old folder: ${oldFolderPath}`);
      }
    } catch (error) {
      console.error("Error renaming folder:", error);
    }
  };

  const deleteFolder = async (bucketName: string, folderPath: string) => {
    if (!userId) {
      console.warn("No user ID found for deleting folder.");
      return;
    }

    try {
      console.log(`Attempting to delete folder: ${folderPath} from bucket: ${bucketName}`);

      const { data: list, error: listError } = await supabasePublic.storage
        .from(bucketName)
        .list(folderPath, {
          limit: 1000,
          offset: 0,
          sortBy: { column: "name", order: "asc" },
        });

      if (listError) {
        console.error("Error listing files and folders:", listError);
        return;
      }

      console.log(`Items in folder to be deleted:`, list);

      for (const item of list) {
        const itemPath = `${folderPath}/${item.name}`;

        console.log(`Processing item: ${item.name}, path: ${itemPath}`);

        if (!item.name.includes('.')) {
          console.log(`Item is a folder, recursing into folder: ${itemPath}`);
          await deleteFolder(bucketName, itemPath);
        } else {
          console.log(`Item is a file, deleting file: ${itemPath}`);
          const { error: removeError } = await supabasePublic.storage
            .from(bucketName)
            .remove([itemPath]);

          if (removeError) {
            console.error(`Error removing file: ${itemPath}`, removeError);
          } else {
            console.log(`Successfully removed file: ${itemPath}`);

            if (item.name.endsWith('.usdz')) {
              const usdzNameWithoutExtension = item.name.slice(0, -5);
              const usdzName = usdzNameWithoutExtension.charAt(0).toUpperCase() + usdzNameWithoutExtension.slice(1);

              const { data: listData, error: fetchError } = await supabaseLienUsdz
                .from(`${bucketName}_lien_usdz`)
                .select('id')
                .eq('user_id', userId)
                .eq('usdz_name', usdzName);

              if (fetchError) {
                console.error('Error fetching usdz entry from the database:', fetchError);
              } else if (listData && listData.length > 0) {
                const usdzId = listData[0].id;
                console.log(`Deleting database entry for usdz file: ${item.name}, ID: ${usdzId}`);

                const { error: dbError } = await supabaseLienUsdz
                  .from(`${bucketName}_lien_usdz`)
                  .delete()
                  .eq('id', usdzId);

                if (dbError) {
                  console.error('Error deleting usdz entry from the database:', dbError);
                } else {
                  console.log(`Successfully deleted database entry for usdz file: ${item.name}`);
                }
              }
            }
          }
        }
      }

      const { error: removeFolderError } = await supabasePublic.storage
        .from(bucketName)
        .remove([folderPath]);

      if (removeFolderError) {
        console.error(`Error removing folder: ${folderPath}`, removeFolderError);
      } else {
        console.log(`Successfully removed folder: ${folderPath}`);
      }
    } catch (error) {
      console.error("Error deleting folder:", error);
    }
  };

  return (
    <div className="fiche-subfolder" onClick={handleFicheClick}>
      <img
        src={closeIcon}
        className="fiche-icon close-icon"
        alt="Close"
        onClick={(e) => {
          e.stopPropagation();
          handleIconClick('confirm', 'Delete Confirmation');
        }}
      />
      <img
        src={editIcon}
        className="fiche-icon edit-icon"
        alt="Edit"
        onClick={(e) => {
          e.stopPropagation();
          handleIconClick('input', 'Rename Folder');
        }}
      />
      <p className="fiche-content">{subFolderName}</p>
      {isInputPopupOpen && (
        <InputPopup
          title={popupTitle}
          onClose={handleCloseInputPopup}
          onSubmit={handleSubmitPopup}
          placeholder="New folder name"
        />
      )}
      {isConfirmPopupOpen && (
        <ConfirmPopup
          title={popupTitle}
          onClose={handleCloseConfirmPopup}
          onConfirm={handleConfirmPopup}
        />
      )}
    </div>
  );
};

export default FicheSubFolder;
