import React, { useState, useEffect } from 'react';
import { supabaseLienUsdz } from '../../lib/supabaseClient';
import SearchObjectView from './SearchObjectView';
import { useParams } from 'react-router-dom';
import FicheUsdzObjectSearch from '../../components/ui/fiches/FicheUsdzObjectSearch';

type RouteParams = {
  bucketName: string;
};

const SearchObjectPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [results, setResults] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [sortKey, setSortKey] = useState<string>('usdz_name');
  const [sortOrder, setSortOrder] = useState<string>('asc');
  const { bucketName } = useParams<RouteParams>();

  console.log("SearchObjectPage rendered with bucketName:", bucketName);

  const handleSearch = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!bucketName) {
      setError('Bucket name is missing.');
      return;
    }

    console.log("Searching for term:", searchTerm);
    const { data, error } = await supabaseLienUsdz
      .from(`${bucketName}_lien_usdz`)
      .select('*')
      .textSearch(`tag_${bucketName}_usdz`, searchTerm);

    if (error) {
      console.error("Search error:", error);
      setError(error.message);
    } else {
      console.log("Search results:", data);
      setResults(data);
      sortResults(sortKey, data);
    }
  };

  const sortResults = (key: string, data = results) => {
    console.log("Sorting results by key:", key, "order:", sortOrder);
    const sortedResults = [...data].sort((a, b) => {
      if (a[key] === null) return 1;
      if (b[key] === null) return -1;

      if (key === 'updated_at') {
        const dateA = new Date(a[key]);
        const dateB = new Date(b[key]);
        return sortOrder === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
      }

      return sortOrder === 'asc'
        ? a[key].localeCompare(b[key])
        : b[key].localeCompare(a[key]);
    });

    setResults(sortedResults);
  };

  const handleDelete = (id: string) => {
    setResults((prevResults) => prevResults.filter((result) => result.usdz_id !== id));
  };

  if (!bucketName) {
    return <p>Bucket name is missing.</p>;
  }

  return (
    <div>
      <SearchObjectView
        searchTerm={searchTerm}
        results={results}
        error={error}
        setSearchTerm={setSearchTerm}
        handleSearch={handleSearch}
        sortKey={sortKey}
        setSortKey={setSortKey}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        sortResults={sortResults}
        bucketName={bucketName}
      />
    </div>
  );
}

export default SearchObjectPage;
