import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { supabasePublic } from "../../lib/supabaseClient";
import ConfirmSignupView from "./ConfirmSignUpView";

const ConfirmSignupPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const confirmSignup = async () => {
      const params = new URLSearchParams(location.search);
      const access_token = params.get("access_token");
      const email = params.get("email");

      if (!access_token || !email) {
        setError("Invalid confirmation link.");
        setLoading(false);
        return;
      }

      const { error } = await supabasePublic.auth.verifyOtp({
        email,
        token: access_token,
        type: 'signup'
      });

      if (error) {
        setError("Error confirming your email. The link may have expired or already been used.");
      } else {
        setLoading(false);
        navigate("/mainfolder");
      }
    };

    confirmSignup();
  }, [location, navigate]);

  return (
    <ConfirmSignupView loading={loading} error={error} />
  );
};

export default ConfirmSignupPage;
