import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import doubleLeftArrow from "../../../assets/double-left-arrow.png";
import leftArrow from "../../../assets/left-arrow.png";
import DownRightArrow from "../../../assets/down-right-arrow.png";
import folderIcon from "../../../assets/folder.png";
import expandIcon from "../../../assets/initial-banner-position.png";
import collapseIcon from "../../../assets/initial-banner-position.png";
import "./FolderRoutingBanner.css";

interface ContainerBannerProps {
  folderPath: string;
  bucketName: string;
}

const ContainerBanner: React.FC<ContainerBannerProps> = ({
  folderPath,
  bucketName,
}) => {
  const navigate = useNavigate();
  const [showSubfolderBackIcon, setShowSubfolderBackIcon] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    setShowSubfolderBackIcon(folderPath.split("/").length > 1);
  }, [folderPath]);

  const handleBackClick = () => {
    navigate("/mainfolder");
  };

  const handlePreviousClick = () => {
    const pathSegments = folderPath.split("/");
    const previousPath = pathSegments.slice(0, -1).join("/");
    navigate(`/${bucketName}/${previousPath}`);
  };

  const mainFolderRender = () => {
    const mainFolder = folderPath.split("/")[0];
    const handleFolderClick = () => {
      navigate(`/${bucketName}/${mainFolder}`);
    };

    return (
      <div className="folder-name">
        <span onClick={handleFolderClick} className="clickable-folder">
          {mainFolder}
          <img src={folderIcon} alt="icon" className="folder-icon" />
        </span>
      </div>
    );
  };

  const subFolderRender = () => {
    const folders = folderPath.split("/").slice(1);
    return folders.map((folder, index) => {
      const path = [folderPath.split("/")[0], ...folders.slice(0, index + 1)].join("/");
      const handleFolderClick = () => {
        navigate(`/${bucketName}/${path}`);
      };

      return (
        <div
          key={index}
          className="folder-name subfolder-name"
          style={{ paddingLeft: `${index * 25 + 10}px` }}
        >
          <img src={DownRightArrow} alt="icon" className="downrightarrow-icon" />
          <span onClick={handleFolderClick} className="clickable-folder">
            {folder}
            <img src={folderIcon} alt="icon" className="folder-icon" />
          </span>
        </div>
      );
    });
  };

  const toggleBannerHeight = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="container-banner">
      <div className="mainfolder-section">
        <img
          src={doubleLeftArrow}
          alt="Back"
          className="mainfolder-back-icon"
          onClick={handleBackClick}
        />
        <div className="text-container">
          {mainFolderRender()}
        </div>
        {showSubfolderBackIcon && (
          <img
            src={isExpanded ? collapseIcon : expandIcon}
            alt={isExpanded ? "Collapse" : "Expand"}
            className={`toggle-icon ${isExpanded ? "collapse" : "expand"}`}
            onClick={toggleBannerHeight}
          />
        )}
      </div>
      <div className="subfolder-section-wrapper">
        {showSubfolderBackIcon && (
          <div className={`subfolder-back-icon-container ${isExpanded ? "visible" : "hidden"}`}>
            <img
              src={leftArrow}
              alt="Previous"
              className="subfolder-back-icon"
              onClick={handlePreviousClick}
            />
          </div>
        )}
        <div
          className={`subfolder-section ${isExpanded ? "expanded" : "collapsed"}`}
        >
          {showSubfolderBackIcon && (
            <div className="folder-hierarchy">
              {subFolderRender()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContainerBanner;
