import React from "react";
import BottomMenu from "../../components/ui/bottom-menu/BottomMenu";
import ContainerBanner from "../../components/ui/banner/FolderRoutingBanner";
import DisplaySubFolderButtons from "../../components/ui/bottom-spaces-buttons/DisplaySubFolderButtons";
import SubFolderCreationButton from "../../components/ui/creations-buttons/SubFolderCreationButton";
import UsdzObjectImportationButton from "../../components/ui/importation-buttons/UsdzObjectImportationButton";
import InfiniteScrollSubFolderComponent from "../../components/ui/infinitescroll/InfiniteScrollSubFolderComponent";

interface SubFolderViewProps {
  mainFolderName: string;
  subfolderName: string;
  folderContents: any[];
  handleButtonClick: () => void;
  handleFileUpload: (file: File | null) => void;
  fileUrl: string | null;
  numItems: number;
  objectUsdzName: string;
  currentFolderPath: string;
  bucketName: string;
  onSubfolderClick: (subfolderName: string) => void;
  onRefresh: () => void;
}

const SubFolderView: React.FC<SubFolderViewProps> = ({
  mainFolderName,
  subfolderName,
  folderContents,
  handleButtonClick,
  handleFileUpload,
  numItems,
  objectUsdzName,
  currentFolderPath,
  bucketName,
  onSubfolderClick,
  onRefresh,
}) => {
  const numUsdzObjects = folderContents.filter(item => item.name.endsWith('.usdz')).length;
  const numSubfolders = folderContents.filter(item => !item.name.endsWith('.usdz')).length;

  const subFolderLabel = numSubfolders > 0 ? "Créer un autre sous-dossier" : "Créer un premier sous-dossier";
  const objectLabel = numUsdzObjects > 0 ? "Importer un autre objet" : "Import ton premier objet";

  return (
    <>
      <ContainerBanner
        folderPath={currentFolderPath}
        bucketName={bucketName}
      />
      <div className="folder-contents">
        <InfiniteScrollSubFolderComponent
          numItems={numItems}
          items={folderContents}
          bucketName={bucketName}
          objectUsdzName={objectUsdzName}
          currentFolderPath={currentFolderPath}
          onSubfolderClick={onSubfolderClick}
          onRefresh={onRefresh}
        />
      </div>
      <DisplaySubFolderButtons>
        <SubFolderCreationButton
          label={subFolderLabel}
          onClick={handleButtonClick}
        />
        <UsdzObjectImportationButton
          label={objectLabel}
          onClick={handleFileUpload}
        />
      </DisplaySubFolderButtons>
      <BottomMenu bucketName={bucketName} />
    </>
  );
};

export default SubFolderView;
