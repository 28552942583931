import React from 'react';
import './MainFolderCreationButton.css';

interface MainFolderCreationButtonProps {
    label: string;
    onClick: () => void;
    style?: React.CSSProperties;
    className?: string;
}

const MainFolderCreationButton: React.FC<MainFolderCreationButtonProps> = ({ label, onClick, style, className }) => {
    return (
        <button
            className={`custom-MainFolderCreationButton ${className}`} 
            onClick={onClick} 
            style={style}
        >
            {label}
        </button>
    );
}

export default MainFolderCreationButton;
