import React from 'react';
import { useParams } from 'react-router-dom';
import { FaSortAlphaDown, FaSortAlphaUp, FaSortNumericDown, FaSortNumericUp } from 'react-icons/fa';
import './SearchObjectView.css';
import BottomMenu from '../../components/ui/bottom-menu/BottomMenu';
import InfiniteScrollUsdzSearchComponent from '../../components/ui/infinitescroll/InfiniteScrollUsdzSearchComponent';

interface SearchViewProps {
  searchTerm: string;
  results: any[];
  error: string | null;
  setSearchTerm: (searchTerm: string) => void;
  handleSearch: (e: React.FormEvent<HTMLFormElement>) => void;
  sortKey: string;
  setSortKey: (key: string) => void;
  sortOrder: string;
  setSortOrder: (order: string) => void;
  sortResults: (key: string, data?: any[]) => void;
  bucketName: string;
}

type RouteParams = {
  bucketName: string;
};

const SearchObjectView: React.FC<SearchViewProps> = ({
  searchTerm,
  results,
  error,
  setSearchTerm,
  handleSearch,
  sortKey,
  setSortKey,
  sortOrder,
  setSortOrder,
  sortResults,
  bucketName,
}) => {
  const { bucketName: urlBucketName } = useParams<RouteParams>();

  console.log("SearchObjectView rendered with bucketName:", urlBucketName);

  const handleSortClick = (key: string) => {
    const newSortOrder = sortKey === key && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortKey(key);
    setSortOrder(newSortOrder);
    sortResults(key);
  };

  return (
    <>
      <div className="search-container">
        <form onSubmit={handleSearch} className="search-form">
          <h1>Search</h1>
          {error && <p className="error">{error}</p>}
          <div className="search-and-sort">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="input"
            />
            <div className="sort-options">
              <div className="sort-option" onClick={() => handleSortClick('usdz_name')}>
                <label>Trier par nom:</label>
                {sortOrder === 'asc' && sortKey === 'usdz_name' ? <FaSortAlphaDown /> : <FaSortAlphaUp />}
              </div>
              <div className="sort-option" onClick={() => handleSortClick('updated_at')}>
                <label>Trier par date:</label>
                {sortOrder === 'asc' && sortKey === 'updated_at' ? <FaSortNumericDown /> : <FaSortNumericUp />}
              </div>
            </div>
          </div>
          <button type="submit">Search</button>
        </form>
        <InfiniteScrollUsdzSearchComponent
          numItems={results.length}
          items={results}
          bucketName={bucketName}
          objectUsdzName=""
          currentFolderPath=""
          onRefresh={() => { /* Handle refresh if needed */ }}
        />
      </div>
      <BottomMenu bucketName={urlBucketName} />
    </>
  );
};

export default SearchObjectView;
