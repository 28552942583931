import React, { useState, useEffect } from 'react';
import './TransfertUsdzPopup.css';
import { supabasePublic } from '../../../lib/supabaseClient';

interface TransfertUsdzPopupProps {
  title: string;
  bucketName: string;
  currentFolderPath: string;
  objectUsdzName: string;
  onClose: () => void;
  onTransfer: (newFolderPath: string) => void;
}

interface Folder {
  name: string;
  fullPath: string;
}

const TransfertUsdzPopup: React.FC<TransfertUsdzPopupProps> = ({ title, bucketName, currentFolderPath, objectUsdzName, onClose, onTransfer }) => {
  const [folderLevels, setFolderLevels] = useState<{ folders: Folder[], selected: string }[]>([]);

  useEffect(() => {
    const fetchInitialFolders = async () => {
      const { data: sessionData, error: sessionError } = await supabasePublic.auth.getSession();
      if (sessionError) {
        console.error("Error fetching session data:", sessionError);
        return;
      }
      const userId = sessionData?.session?.user.id;
      if (!userId || !bucketName) return;

      const { data: mainFolderData, error: mainFolderError } = await supabasePublic.storage
        .from(bucketName)
        .list(`${userId}/`, {
          limit: 100,
          offset: 0,
          sortBy: { column: "name", order: "asc" }
        });

      if (mainFolderError) {
        console.error("Error fetching main folder list:", mainFolderError);
      } else {
        const validMainFolders = await Promise.all(
          mainFolderData
            ?.filter(folder => folder.name !== '.placeholder' && !folder.name.endsWith('.usdz'))
            .map(async folder => {
              const subFolders = await fetchSubFolders(`${userId}/${folder.name}`);
              if (subFolders.length > 0) {
                return {
                  name: folder.name,
                  fullPath: `${userId}/${folder.name}`,
                };
              } else {
                return null;
              }
            }) || []
        );

        setFolderLevels([{ folders: validMainFolders.filter(Boolean) as Folder[], selected: '' }]);
      }
    };

    fetchInitialFolders();
  }, [bucketName]);

  const fetchSubFolders = async (parentPath: string) => {
    const { data: folderData, error: folderError } = await supabasePublic.storage
      .from(bucketName)
      .list(parentPath, {
        limit: 100,
        offset: 0,
        sortBy: { column: "name", order: "asc" }
      });

    if (folderError) {
      console.error("Error fetching subfolder list:", folderError);
      return [];
    } else {
      return folderData
        ?.filter(folder => folder.name !== '.placeholder' && !folder.name.endsWith('.usdz'))
        .map(folder => ({
          name: folder.name,
          fullPath: `${parentPath}/${folder.name}`
        })) || [];
    }
  };

  const handleFolderChange = async (levelIndex: number, selectedFolder: string) => {
    const newFolderLevels = folderLevels.slice(0, levelIndex + 1);
    newFolderLevels[levelIndex].selected = selectedFolder;

    const parentPath = newFolderLevels[levelIndex].folders.find(folder => folder.name === selectedFolder)?.fullPath;
    if (parentPath) {
      const subFolders = await fetchSubFolders(parentPath);
      if (subFolders && subFolders.length > 0) {
        newFolderLevels.push({ folders: subFolders, selected: '' });
      }
    }

    setFolderLevels(newFolderLevels);
  };

  const handleConfirm = () => {
    const selectedPath = folderLevels.map(level => level.selected).filter(Boolean).join('/');
    const isSubFolderSelected = folderLevels.length > 1 && folderLevels.some(level => level.selected);

    if (isSubFolderSelected) {
      onTransfer(selectedPath);
    }
    onClose();
  };

  const isTransferDisabled = folderLevels.length < 2 || !folderLevels.some((level, index) => index > 0 && level.selected);

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="popup-header">
          <h2>{title}</h2>
          <button className="close-button" onClick={onClose}>×</button>
        </div>
        <div className="popup-body">
          {folderLevels.map((level, index) => (
            <div key={index}>
              <label htmlFor={`folder-select-${index}`}>
                {index === 0 ? 'Select a main folder:' : 'Select a subfolder:'}
              </label>
              <select
                id={`folder-select-${index}`}
                value={level.selected || ''}
                onChange={(e) => handleFolderChange(index, e.target.value)}
                className="select-folder"
              >
                <option value="" disabled>
                  {index === 0 ? 'Select a main folder' : 'Select a subfolder'}
                </option>
                {level.folders.map((folder, folderIndex) => (
                  <option key={folderIndex} value={folder.name}>{folder.name}</option>
                ))}
              </select>
            </div>
          ))}
        </div>
        <div className="popup-footer">
          <button onClick={handleConfirm} disabled={isTransferDisabled}>Transfer</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default TransfertUsdzPopup;
