import React, { useState, useEffect } from 'react';
import './TagPopup.css';
import { tableauCategories, meubleCategories, mobilierCategories } from '../../utils/TagPopCategorie';

interface NewPopupProps {
  title: string;
  onClose: () => void;
  onAction: (selectedTags: string[]) => void;
  initialSelectedTags: string[]; // New prop for initial selected tags
}

const tagCategories: { [key: string]: string[] } = {
  Tableau: [...tableauCategories.style, ...tableauCategories.technique, ...tableauCategories.sujet, ...tableauCategories.couleursDominantes],
  Meuble: [...meubleCategories.style, ...meubleCategories.couleur, ...meubleCategories.materiau, ...meubleCategories.type],
  Mobilier: [...mobilierCategories.style, ...mobilierCategories.couleur, ...mobilierCategories.materiau, ...mobilierCategories.type]
};

const subcategories: { [key: string]: string[] } = {
  Tableau: ['style', 'technique', 'sujet', 'couleursDominantes'],
  Meuble: ['style', 'couleur', 'materiau', 'type'],
  Mobilier: ['style', 'couleur', 'materiau', 'type']
};

const TagPopup: React.FC<NewPopupProps> = ({ title, onClose, onAction, initialSelectedTags }) => {
  const [firstPartClicked, setFirstPartClicked] = useState<boolean>(false);
  const [secondPartClicked, setSecondPartClicked] = useState<boolean>(false);
  const [fullWidthButton, setFullWidthButton] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(initialSelectedTags[0] || null);
  const [selectedSubCategory, setSelectedSubCategory] = useState<string | null>(null);
  const [clickedButtons, setClickedButtons] = useState<Set<string>>(new Set(initialSelectedTags.slice(1)));
  const [activeFirstButton, setActiveFirstButton] = useState<string | null>(initialSelectedTags[0] || null);
  const [activeSecondButton, setActiveSecondButton] = useState<string | null>(null);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState<boolean>(initialSelectedTags.length > 0);

  useEffect(() => {
    setIsSubmitEnabled(clickedButtons.size > 0);
  }, [clickedButtons]);

  const handleFirstPartAction = (category: string) => {
    setFirstPartClicked(true);
    setSelectedCategory(category);
    setSecondPartClicked(false); // Reset second part state when selecting a new category
    setFullWidthButton(category);
    setActiveFirstButton(category);
    setActiveSecondButton(null); // Reset active second part button
  };

  const handleSecondPartAction = (subcategory: string) => {
    setSecondPartClicked(true);
    setSelectedSubCategory(subcategory);
    setActiveSecondButton(subcategory);
  };

  const handleThirdPartAction = (action: string) => {
    setClickedButtons(prev => {
      const newClickedButtons = new Set(prev);
      if (newClickedButtons.has(action)) {
        newClickedButtons.delete(action);
      } else {
        newClickedButtons.add(action);
      }
      return newClickedButtons;
    });
  };

  const handleSubmit = () => {
    const selectedTags = [selectedCategory, ...Array.from(clickedButtons)].filter(Boolean) as string[];
    onAction(selectedTags);
    onClose(); // Close the popup
  };

  const getThirdPartButtons = () => {
    if (selectedCategory === 'Tableau' && selectedSubCategory) {
      switch (selectedSubCategory) {
        case 'style':
          return tableauCategories.style;
        case 'technique':
          return tableauCategories.technique;
        case 'sujet':
          return tableauCategories.sujet;
        case 'couleursDominantes':
          return tableauCategories.couleursDominantes;
        default:
          return [];
      }
    } else if (selectedCategory === 'Meuble' && selectedSubCategory) {
      switch (selectedSubCategory) {
        case 'style':
          return meubleCategories.style;
        case 'couleur':
          return meubleCategories.couleur;
        case 'materiau':
          return meubleCategories.materiau;
        case 'type':
          return meubleCategories.type;
        default:
          return [];
      }
    } else if (selectedCategory === 'Mobilier' && selectedSubCategory) {
      switch (selectedSubCategory) {
        case 'style':
          return mobilierCategories.style;
        case 'couleur':
          return mobilierCategories.couleur;
        case 'materiau':
          return mobilierCategories.materiau;
        case 'type':
          return mobilierCategories.type;
        default:
          return [];
      }
    }
    return [];
  };

  const thirdPartButtons = getThirdPartButtons();

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="popup-header">
          <h2>{title}</h2>
        </div>
        <div className="popup-body">
          <div className={`part-1 ${firstPartClicked ? 'active' : ''}`}>
            {['Tableau', 'Meuble', 'Mobilier'].map(category => (
              <button
                key={category}
                onClick={() => handleFirstPartAction(category)}
                className={`part-1-button first-part-button btn ${activeFirstButton === category ? 'active' : ''}`}
                disabled={firstPartClicked && activeFirstButton !== category} // Disable other buttons
              >
                <span className="btn-text">{category}</span>
              </button>
            ))}
          </div>
          <div className={`part-2 grid ${firstPartClicked ? 'active' : ''}`}>
            {selectedCategory && subcategories[selectedCategory].map(subcategory => (
              <button
                key={subcategory}
                className={`${subcategory}-button ${activeSecondButton === subcategory ? 'active' : ''} part-2-button`}
                onClick={() => handleSecondPartAction(subcategory)}
                disabled={!firstPartClicked}
              >
                {subcategory.charAt(0).toUpperCase() + subcategory.slice(1)}
              </button>
            ))}
          </div>
          <div className={`part-3 grid ${secondPartClicked ? 'active' : ''}`}>
            {thirdPartButtons.map((item, index) => (
              <button
                key={index}
                className={`tag-button ${clickedButtons.has(item) ? 'clicked' : ''} ${secondPartClicked ? 'enabled' : ''}`}
                onClick={() => handleThirdPartAction(item)}
                disabled={!secondPartClicked}
              >
                {item}
              </button>
            ))}
          </div>
          <div className="part-4">
            <button
              className="submit-button"
              onClick={handleSubmit}
              disabled={!isSubmitEnabled}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TagPopup;
