import React from "react";
import "./ConfirmSignUpView.css";
import Loading from "../../components/ui/loading/Loading";

interface ConfirmSignupViewProps {
  loading: boolean;
  error: string | null;
}

const ConfirmSignupView: React.FC<ConfirmSignupViewProps> = ({ loading, error }) => {
  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <p className="error">{error}</p>;
  }

  return (
    <div className="confirm-signup-container">
      <h1>Signup Confirmed</h1>
      <p>Your email has been confirmed successfully!</p>
    </div>
  );
};

export default ConfirmSignupView;
