import React from "react";
import MainFolderCreationButton from "../../components/ui/creations-buttons/MainFolderCreationButton";
import BottomMenu from "../../components/ui/bottom-menu/BottomMenu";
import InfiniteScrollMainFolderComponent from "../../components/ui/infinitescroll/InfiniteScrollMainFolderComponent";
import FicheFolder from "../../components/ui/fiches/FicheMainFolder";
import FicheUsdzObject from "../../components/ui/fiches/FicheUsdzObject";
import "./MainFolderView.css";

interface MainFolderViewProps {
  filteredBuckets: any[];
  bucketObjects: any[];
  onButtonClick: () => void;
  componentType: "FicheFolder";
  bucketName: string;
  renameFolder: (bucketName: string, oldName: string, newName: string) => void;
  deleteFolder: (bucketName: string, mainFolderName: string) => void;
}

const MainFolderView: React.FC<MainFolderViewProps> = ({
  onButtonClick,
  bucketObjects,
  componentType,
  bucketName,
  renameFolder,
  deleteFolder,
}) => {
  const filteredBucketObjects = bucketObjects.filter(
    (obj) => obj.name !== "./placeholder"
  );
  const buttonLabel =
    filteredBucketObjects.length > 0
      ? "Créer d'autres espaces de stockage"
      : "Créer votre premier espace de stockage";

  const componentMap = {
    FicheFolder,
    FicheUsdzObject,
  };

  const ComponentToRender = componentMap[componentType];

  return (
    <>
      <div className="main-folder">
        {filteredBucketObjects.length === 0 ? (
          <div className="text-content">
            <p>
              Vous n'avez pas encore d'environnement de stockage virtuel,
              cliquez en bas pour démarrer.
            </p>
            <MainFolderCreationButton
              label="Créez votre premier espace de stockage"
              onClick={onButtonClick}
            />
          </div>
        ) : (
          <div className="content">
            <MainFolderCreationButton
              label={buttonLabel}
              onClick={onButtonClick}
            />
            <div>
              <InfiniteScrollMainFolderComponent
                numItems={filteredBucketObjects.length}
                Component={(props) => (
                  <ComponentToRender
                    {...props}
                    bucketName={bucketName}
                    renameFolder={renameFolder}
                  />
                )}
                bucketObjects={filteredBucketObjects}
                bucketName={bucketName}
                renameFolder={renameFolder}
                deleteFolder={deleteFolder}
              />
            </div>
          </div>
        )}
      </div>
      <BottomMenu bucketName={bucketName} />
    </>
  );
};

export default MainFolderView;
