import React from 'react';
import './Button.css';

interface ButtonProps {
    label: string;
    onClick: () => void;
    disabled?: boolean;
    style?: React.CSSProperties;
}

const Button: React.FC<ButtonProps> = ({ label, onClick, disabled = false, style }) => {
    return (
        <button 
            className={`custom-button ${disabled ? 'disabled' : ''}`} 
            onClick={onClick} 
            disabled={disabled} 
            style={style}
        >
            {label}
        </button>
    );
}

export default Button;
