import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import SubFolderView from "./SubFolderView";
import { supabasePublic } from "../../lib/supabaseClient";
import InputPopup from "../../components/ui/popup/InputPopup";
import { supabaseLienUsdz } from "../../lib/supabaseClient";

type RouteParams = {
  bucketName: string;
  mainFolderName: string;
  '*': string;
};

const SubFolderPage: React.FC = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [folderContents, setFolderContents] = useState<any[]>([]);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [fileId, setFileId] = useState<string | null>(null);
  const { bucketName, mainFolderName, '*': rest } = useParams<RouteParams>();

  const mainFolder = mainFolderName ?? "defaultMainFolder";
  const currentFolderPath = rest ? `${mainFolder}/${rest}` : mainFolder;
  const objectUsdzName = currentFolderPath || "defaultUsdzName";

  const navigate = useNavigate();

  const fetchData = async () => {
    console.log("Fetching data...");
    const { data: sessionData, error: sessionError } = await supabasePublic.auth.getSession();
    if (sessionError) {
      console.error("Error fetching session data:", sessionError);
      return;
    }

    const userId = sessionData?.session?.user.id;
    console.log("User ID:", userId);

    if (!userId || !bucketName || !currentFolderPath) return;

    const currentFolderName = `${userId}/${currentFolderPath}/`;
    console.log("Full Folder Name:", currentFolderName);

    const { data, error } = await supabasePublic.storage
      .from(bucketName)
      .list(currentFolderName, {
        limit: 100,
        offset: 0,
        sortBy: { column: "name", order: "asc" },
      });

    if (error) {
      console.error("Error fetching folder contents:", error);
    } else {
      console.log("Folder contents fetched:", data);
      const filteredData = data.filter(item => item.name !== '.placeholder');
      setFolderContents(filteredData);
    }
  };

  useEffect(() => {
    fetchData();
  }, [bucketName, currentFolderPath]);

  const handleButtonClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleSubmitPopup = async (subFolderName: string) => {
    const { data: sessionData, error: sessionError } = await supabasePublic.auth.getSession();
    if (sessionError) {
      console.error("Error fetching session data:", sessionError);
      return;
    }

    const userId = sessionData?.session?.user.id;
    if (!userId || !bucketName || !currentFolderPath || !subFolderName) return;

    const fullFolderName = `${userId}/${currentFolderPath}/${subFolderName}/`;

    const { error: uploadError } = await supabasePublic.storage
      .from(bucketName)
      .upload(fullFolderName + '/.placeholder', new Blob([]), {
        cacheControl: '3600',
        upsert: false
      });

    if (uploadError) {
      console.error("Error creating new folder:", uploadError);
      return;
    }

    const newFolder = { name: subFolderName };
    setFolderContents(prevContents => [...prevContents, newFolder]);
    setIsPopupOpen(false);
  };

  const handleFileUpload = async (file: File | null) => {
    if (!file) return;

    const { data: sessionData, error: sessionError } = await supabasePublic.auth.getSession();
    if (sessionError) {
      console.error("Error fetching session data:", sessionError);
      return;
    }

    const userId = sessionData?.session?.user.id;
    if (!userId || !bucketName || !currentFolderPath) return;

    const filePath = `${userId}/${currentFolderPath}/${file.name}`;

    const { error } = await supabasePublic.storage
      .from(bucketName)
      .upload(filePath, file, {
        cacheControl: '3600',
        upsert: false
      });

    if (error) {
      console.error('Error uploading file:', error);
    } else {
      console.log('File uploaded successfully.');

      const { data } = supabasePublic.storage
        .from(bucketName)
        .getPublicUrl(filePath);

      if (data.publicUrl) {
        console.log('Public URL:', data.publicUrl);
        setFileUrl(data.publicUrl);

        const currentFolderName = `${userId}/${currentFolderPath}/`;
        console.log('currentFolderName: ',currentFolderName)
        const { data: listData, error: listError } = await supabasePublic.storage
          .from(bucketName)
          .list(currentFolderName, {
            limit: 100,
            offset: 0,
            sortBy: { column: "name", order: "asc" },
            search: file.name
          });

        if (listError) {
          console.error("Error fetching updated folder contents:", listError);
        } else {
          console.log("Resultat du file.name :", listData);

          if (listData && listData.length > 0) {
            const ObjectUsdzId = listData[0].id;
            setFileId(ObjectUsdzId);
            console.log("Fetched File ID2:", ObjectUsdzId);

            const usdz_name_without_usdz = file.name.slice(0, -5);
            const usdz_name = usdz_name_without_usdz.charAt(0).toUpperCase()+usdz_name_without_usdz.slice(1);

            const insertData = {
              usdz_id: ObjectUsdzId,
              user_id: userId,
              created_at: new Date().toISOString(),
              lien_url: data.publicUrl,
              updated_at: new Date().toISOString(),
              usdz_name: usdz_name
            };

            console.log('Data to be sent:', insertData);

            const { data: dbData, error: dbError } = await supabaseLienUsdz
              .from(`${bucketName}_lien_usdz`)
              .insert([insertData])
              .select();

            if (dbError) {
              console.error('Error inserting data into the database:', dbError);
            } else {
              console.log('Data inserted into the database successfully:', dbData);
            }

            setFolderContents(prevContents => [...prevContents, { name: file.name }]);
          }
        }
      } else {
        console.error('Error creating public URL');
      }
    }
  };

  const handleSubfolderClick = (subfolderName: string) => {
    const newPath = `/${bucketName}/${currentFolderPath}/${subfolderName}`;
    navigate(newPath, { replace: true });
  };

  useEffect(() => {
    fetchData();
  }, [currentFolderPath]);

  return (
    <>
      <SubFolderView
        mainFolderName={mainFolder}
        subfolderName={rest || ""}
        folderContents={folderContents}
        handleButtonClick={handleButtonClick}
        handleFileUpload={handleFileUpload}
        fileUrl={fileUrl}
        numItems={folderContents.length}
        currentFolderPath={currentFolderPath || ""}
        objectUsdzName={objectUsdzName}
        bucketName={bucketName || ""}
        onSubfolderClick={handleSubfolderClick}
        onRefresh={fetchData}
      />
      {isPopupOpen && (
        <InputPopup
          title="Enter Subfolder Name"
          onClose={handleClosePopup}
          onSubmit={handleSubmitPopup}
          placeholder="Subfolder name"
        />
      )}
    </>
  );
};

export default SubFolderPage;
