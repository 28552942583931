import React from "react";
import BottomMenu from "../../components/ui/bottom-menu/BottomMenu";
import "./ProfileView.css";
import Loading from "../../components/ui/loading/Loading";

interface ProfileViewProps {
  bucketName: string;
  profiles: any[];
  loading: boolean;
  onLogout: () => void;
}

const ProfileView: React.FC<ProfileViewProps> = ({ bucketName, profiles, loading, onLogout }) => {
  return (
    <>
      <div className="ProfilePage">
        <div>Profile</div>
        {loading ? (
          <Loading />
        ) : (
          <ul>
            {profiles.map((profile) => (
              <li key={profile.id}>
                <p>
                  <strong>Profession :</strong> {profile.profession}
                </p>
                <p>
                  <strong>Name:</strong> {profile.name}
                </p>
                <p>
                  <strong>Address:</strong> {profile.address}
                </p>
                <p>
                  <strong>Telephone:</strong> {profile.telephone}
                </p>
                <p>
                  <strong>Website:</strong>{" "}
                  <a href={profile.website}>{profile.website}</a>
                </p>
                <p>
                  <strong>Picture:</strong>{" "}
                  <img
                    src={profile.picture}
                    alt={profile.name}
                    className="profile-picture"
                  />
                </p>
              </li>
            ))}
            <button onClick={onLogout}>Logout</button>
          </ul>
        )}
      </div>
      <BottomMenu bucketName={bucketName} />
    </>
  );
};

export default ProfileView;
