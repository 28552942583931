import React from 'react';
import './DisplaySubFolderButtons.css';

interface DisplaySubFolderButtonsProps {
  children: React.ReactNode;
}

const DisplaySubFolderButtons: React.FC<DisplaySubFolderButtonsProps> = ({ children }) => {
  return (
    <div className="DisplaySubFolderButtons">
      <div className="buttons-folder">
        {children}
      </div>
    </div>
  );
};

export default DisplaySubFolderButtons;
