import React, { useState } from 'react';
import { supabasePublic } from '../../lib/supabaseClient';
import { useNavigate } from 'react-router-dom';
import SignupView from './SignupView';

const SignupPage: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { error } = await supabasePublic.auth.signUp({
      email,
      password,
      options: {
        emailRedirectTo: 'http://192.168.1.18:3000/confirm-signup'
      }
    });

    if (error) {
      setError(error.message);
    } else {
      navigate('/verification');
    }
  };

  return (
    <SignupView
      email={email}
      password={password}
      error={error}
      setEmail={setEmail}
      setPassword={setPassword}
      handleSignUp={handleSignUp}
    />
  );
};

export default SignupPage;
