import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './FicheMainFolder.css';
import closeIcon from '../../../assets/delete.png';
import editIcon from '../../../assets/edit.png';
import InputPopup from '../popup/InputPopup';
import ConfirmPopup from '../popup/ConfirmPopup';
import { supabasePublic } from '../../../lib/supabaseClient';

interface FicheFolderProps {
  mainFolderName: string;
  bucketName: string;
  renameFolder: (bucketName: string, oldName: string, newName: string) => void;
  deleteFolder: (bucketName: string, mainFolderName: string) => void;
}

const FicheFolder: React.FC<FicheFolderProps> = ({ mainFolderName, bucketName, renameFolder, deleteFolder }) => {
  const [isInputPopupOpen, setIsInputPopupOpen] = useState(false);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [popupTitle, setPopupTitle] = useState('');
  const [identityId, setIdentityId] = useState<string | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const { data: { user }, error } = await supabasePublic.auth.getUser();
      if (user && user.identities && user.identities.length > 0) {
        setIdentityId(user.identities[0].identity_id);
      } else if (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const handleFicheClick = () => {
    if (!isInputPopupOpen && !isConfirmPopupOpen && identityId && mainFolderName) {
      navigate(`/${bucketName}/${mainFolderName}`);
    } else {
      console.warn('Cannot navigate: identityId or mainFolderName is missing.');
    }
  };

  const handleIconClick = (type: 'input' | 'confirm', title: string) => {
    setPopupTitle(title);
    if (type === 'input') {
      setIsInputPopupOpen(true);
    } else {
      setIsConfirmPopupOpen(true);
    }
  };

  const handleCloseInputPopup = () => {
    setIsInputPopupOpen(false);
  };

  const handleCloseConfirmPopup = () => {
    setIsConfirmPopupOpen(false);
  };

  const handleSubmitPopup = async (newFolderName: string) => {
    console.log('Renaming folder to:', newFolderName);
    await renameFolder(bucketName, mainFolderName, newFolderName);
    setIsInputPopupOpen(false);
  };  

  const handleConfirmPopup = async (confirmed: boolean) => {
    if (confirmed) {
      console.log(`Confirmed delete for folder: ${mainFolderName}`);
      await deleteFolder(bucketName, mainFolderName);
    }
    setIsConfirmPopupOpen(false);
  };

  return (
    <div className="fiche-mainfolder" onClick={handleFicheClick}>
      <img
        src={closeIcon}
        className="fiche-icon close-icon"
        alt="Close"
        onClick={(e) => {
          e.stopPropagation();
          handleIconClick('confirm', 'Delete Confirmation');
        }}
      />
      <img
        src={editIcon}
        className="fiche-icon edit-icon"
        alt="Edit"
        onClick={(e) => {
          e.stopPropagation();
          handleIconClick('input', 'Renommer le fichier');
        }}
      />
      <p className="fiche-content">{mainFolderName}</p>
      {isInputPopupOpen && (
        <InputPopup
          title={popupTitle}
          onClose={handleCloseInputPopup}
          onSubmit={handleSubmitPopup}
          placeholder={"1"}
        />
      )}
      {isConfirmPopupOpen && (
        <ConfirmPopup
          title={popupTitle}
          onClose={handleCloseConfirmPopup}
          onConfirm={(confirmed: boolean) => handleConfirmPopup(confirmed)}
        />
      )}
    </div>
  );
};

export default FicheFolder;
