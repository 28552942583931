import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import FicheSubFolder from "../fiches/FicheSubFolder";
import FicheUsdzObject from "../fiches/FicheUsdzObject";
import "./InfiniteScrollSubFolderComponent.css";

type Item = {
  id: number | string;
  name: string;
};

interface InfiniteScrollSubFolderComponentProps {
  numItems: number;
  items: Item[];
  bucketName: string;
  objectUsdzName: string;
  currentFolderPath: string;
  onSubfolderClick: (subfolderName: string) => void;
  onRefresh: () => void;
}

const InfiniteScrollSubFolderComponent: React.FC<InfiniteScrollSubFolderComponentProps> = ({
  numItems,
  items,
  bucketName,
  objectUsdzName,
  currentFolderPath,
  onSubfolderClick,
  onRefresh
}) => {
  const [displayItems, setDisplayItems] = useState<Item[]>([]);
  const [count, setCount] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    console.log("Items changed:", items);
    setDisplayItems([]);
    setCount(0);
    setHasMore(true);
  }, [items]);

  useEffect(() => {
    if (count === 0) {
      fetchMoreData();
    }
  }, [count, items]);

  const fetchMoreData = () => {
    console.log("Fetching more data...");
    console.log("Current count:", count);
    console.log("Current displayItems length:", displayItems.length);
    const remainingItems = numItems - displayItems.length;
    console.log("Remaining items to fetch:", remainingItems);
    const itemsToFetch = Math.min(remainingItems, 10);
    if (itemsToFetch <= 0) {
      console.log("No more items to fetch");
      setHasMore(false);
      return;
    }

    const newItems = items.slice(displayItems.length, displayItems.length + itemsToFetch);
    setCount(count + itemsToFetch);
    setDisplayItems(prevItems => [...prevItems, ...newItems]);
    console.log("New displayItems length:", displayItems.length + newItems.length);
  };

  const removeUsdzExtension = (fileName: string) => {
    return fileName.replace(/\.usdz$/, '');
  };

  return (
    <div className="scroll-subfolder">
      <InfiniteScroll
        dataLength={displayItems.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={''}
      >
        <div className="InfiniteScrollSubFolderComponent-container">
          {displayItems.map((item, index) => {
            const itemKey = item.id ?? `${item.name}-${index}`;
            return item.name.endsWith(".usdz") ? (
              <FicheUsdzObject
                key={itemKey}
                objectUsdzName={removeUsdzExtension(item.name)}
                bucketName={bucketName}
                currentFolderPath={currentFolderPath}
                onDelete={onRefresh}
                onRefresh={onRefresh} // Pass onRefresh prop here
              />
            ) : (
              <FicheSubFolder
                key={itemKey}
                mainFolderName={item.name}
                bucketName={bucketName}
                subFolderName={item.name}
                currentFolderPath={currentFolderPath}
                onClick={() => onSubfolderClick(item.name)}
                onDelete={onRefresh}
              />
            );
          })}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default InfiniteScrollSubFolderComponent;
