import React from 'react';
import './Loading.css';
import LoadingGif from '../../../assets/loading.gif'

const Loading: React.FC = () => {
  return (
    <div className="loading-container">
      <img src={LoadingGif} alt="Loading..." className="loading-gif" />
    </div>
  );
};

export default Loading;
