export const tableauCategories = {
    style: [
      'Abstrait',
      "Figuratif",
      "Impressionnisme",
      "Cubisme",
      "Réalisme",
      "Surréalisme",
      "Pop Art",
      "Street Art",
      "Minimalisme",
      "Expressionnisme",
      "Baroque",
      "Rococo"
    ],
    technique: [
      "Peinture à l'huile",
      "Acrylique",
      "Aquarelle",
      "Encre",
      "Dessin au crayon",
      "Gravure",
      "Collage",
      "Pastel",
      "Fusain",
      "Gouache",
      "Fresque",
      "Tempera"
    ],
    sujet: [
      "Paysage",
      "Portrait",
      "Nature morte",
      "Scène de genre",
      "Animaux",
      "Abstrait",
      "Nu",
      "Urbain",
      "Marin",
      "Mythologie",
      "Religieux",
      "Historique"
    ],
    couleursDominantes: [
      "Multicolore",
      "Noir et blanc",
      "Bleu",
      "Rouge",
      "Vert",
      "Jaune",
      "Orange",
      "Violet",
      "Rose",
      "Marron",
      "Gris",
      "Beige"
    ]
  };
  
  export const meubleCategories = {
    type: [
      "Canapé",
      "Fauteuil",
      "Chaise",
      "Table basse",
      "Table à manger",
      "Bureau",
      "Lit",
      "Commode",
      "Armoire",
      "Étagère",
      "Buffet",
      "Tabouret"
    ],
    materiau: [
      "Bois",
      "Métal",
      "Verre",
      "Plastique",
      "Rotin",
      "Tissu",
      "Cuir",
      "Marbre",
      "Béton",
      "Bambou",
      "Carton",
      "Acier"
    ],
    style: [
      "Moderne",
      "Contemporain",
      "Scandinave",
      "Industriel",
      "Rustique",
      "Vintage",
      "Classique",
      "Minimaliste",
      "Art déco",
      "Bohème",
      "Médiéval",
      "Colonial"
    ],
    couleur: [
      "Blanc",
      "Noir",
      "Gris",
      "Marron",
      "Beige",
      "Bleu",
      "Vert",
      "Rouge",
      "Jaune",
      "Rose",
      "Violet",
      "Orange"
    ]
  };
  
  export const mobilierCategories = {
    type: [
      "Luminaires",
      "Tapis",
      "Rideaux",
      "Coussins",
      "Miroirs",
      "Décorations murales",
      "Vases",
      "Bibliothèques",
      "Rangement",
      "Horloges",
      "Plantes",
      "Photophores"
    ],
    materiau: [
      "Bois",
      "Métal",
      "Verre",
      "Céramique",
      "Tissu",
      "Rotin",
      "Papier",
      "Pierre",
      "Plastique",
      "Bambou",
      "Acier",
      "Cuivre"
    ],
    style: [
      "Moderne",
      "Contemporain",
      "Industriel",
      "Scandinave",
      "Rustique",
      "Vintage",
      "Minimaliste",
      "Éclectique",
      "Art déco",
      "Bohème",
      "Nautique",
      "Méditerranéen"
    ],
    couleur: [
      "Blanc",
      "Noir",
      "Gris",
      "Marron",
      "Beige",
      "Multicolore",
      "Bleu",
      "Vert",
      "Rouge",
      "Jaune",
      "Rose",
      "Violet"
    ]
  };
  